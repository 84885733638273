<template>
  <v-container fluid >
      <v-layout
        row
        fill-height
        justify-center
        align-center
        v-if="loading"
    >
        <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
        />
    </v-layout>
    <v-row dense class="mx-auto">
      <v-col
          :cols="3"
          >
        <v-card class="searchArea overflow-y-auto overflow-x-hidden" v-bind:style="{height:contentHeight}">
          <v-card-title class="d-flex justify-center pa-0 mt-2 mb-2">
            登録済み圃場数 {{fieldNum}}
          </v-card-title>
          <RegisterFieldDialog ref="registerFieldDialog" @reload="reload" :area="area" :points="points" :Cesium="Cesium"></RegisterFieldDialog>
          <UpdateFieldDialog ref="udpateFieldDialog" @reload="reload" :area="area" :field="selectField" :Cesium="Cesium"></UpdateFieldDialog>
          <v-divider></v-divider>
          <v-row >
            <v-col
                :cols="12"
                >
              <v-card
                color="#3c3c3c"
                dark
              >
                <v-text-field
                  class="pa-2 pt-5"
                  v-model="searchFields"
                  :counter="50"
                  label="圃場の検索"
                  required
                ></v-text-field>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
                v-for="field in displayFields"
                :cols="12"
                :key="field.id"
                >
              <v-card
                color="#3c3c3c"
                dark
              >
                <v-card-title class="text-h5">
                  {{ field.name }}
                </v-card-title>

                <v-card-subtitle>
                  <br> {{ field.address }}
                  <br> {{ field.description }}
                </v-card-subtitle>

                <v-card-actions>
                  <v-card-subtitle class="red--text" v-if="!hasPolygon(field.id)">
                    {{ '圃場エリア未設定' }}
                  </v-card-subtitle>
                  <v-btn
                    outlined
                    dark
                    v-if="hasPolygon(field.id)"
                    @click.stop="flyTo(field.id)"
                  >
                    圃場エリア確認
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn icon>
                    <v-icon @click.stop="onClickUpdate(field)">mdi-application-edit</v-icon>
                  </v-btn>
                  <v-btn icon>
                    <v-icon @click.stop="onClickDelete(field)">mdi-delete</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col
          :cols="9"
          >
        <div class="viewer" v-bind:style="{height:viewerHeight}" @click.right.prevent="toggleMeasureArea">
          <vc-viewer @ready="ready" :infoBox="false" :selectionIndicator="false">
            <vc-layer-imagery>
              <vc-provider-imagery-arcgis-mapserver :url="url" />
            </vc-layer-imagery>

            <vc-entity v-for="polygon in polygons" :key="polygon.id" @click="polygonClick(polygon)">
              <vc-graphics-polygon
                :hierarchy="convertHierarchy(polygon)"
                :material="material"
                :extrudedHeight="0.0"
                :closeTop="false"
                :closeBottom="false"
                :ref="polygonRef(polygon.id)"
                :outline="true"
                :outlineColor="outLineMaterial"
                :outlineWidth="5"
              ></vc-graphics-polygon>
            </vc-entity>
            <vc-measure-area
              ref="measureArea"
              @activeEvt="activeEvt"
              @measureEvt="measureEvt"
              @movingEvt="movingEvt"
              backgroundColor="rgba(0, 0, 0, 0)"
              fillColor="rgba(0, 0, 0, 0)"
              :clampToGround="false"
              :removeLastPosition="false"
            ></vc-measure-area>
            <div class="tool">
              <v-btn 
                outlined
                dark
                @click="toggleMeasureArea">{{ areaMeasuring ? '終了' : 'エリア作成' }}</v-btn>
              <span> * 右クリックで開始、終了</span>
            </div>
          </vc-viewer>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RegisterFieldDialog from '@/components/comps/RegisterFieldDialog'
import UpdateFieldDialog from '@/components/comps/UpdateFieldDialog'
import AuthUtils from '@/utils/AuthUtils'
import router from "../../router";
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  name: "RegisterField",
  components: {
    RegisterFieldDialog,
    UpdateFieldDialog
  },
  mounted() {
    this.reload();
    this.windowSize.width = window.innerWidth;
    this.windowSize.height = window.innerHeight;
  },
  data() {
    return {
      Cesium: {},
      viewer: {},
      loading: false,
      fields: [],
      selectFieldId: "",
      selectField: {},
      url: "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer",
      areaMeasuring: false,
      polygons: [],
      drawData: {},
      material: [0.1, 1, 0.2, 0.5],
      outLineMaterial: [0.1, 1, 0.2, 1],
      geoready: false,
      zoomX: 0,
      zoomY: 0,
      windowSize: {
        width:0,
        height:0
      },
      measuring: false,
      points: [],
      area: "",
      searchFields: ""

    }
  },
  methods: {
    ready(cesiumInstance) {
      const { Cesium, viewer } = cesiumInstance;
      this.Cesium = Cesium;
      this.viewer = viewer;
      viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
      this.zoom();
    },
    reload() {
      this.points = [];
      this.polygons = [];
      this.area = "";
      this.checkLoggedIn();
      AuthUtils.checkAccountPermission(this.$session, router);
      const current_user = AuthUtils.currentUser(this.$session);
      axios.get(`/api/geoapi_from_postal/?zipcode=${current_user.zipcode}`).then((res) => {
        this.zoomX = res.data.x;
        this.zoomY = res.data.y;
        this.geoready = true;
      });
      axios.get(`/api/fields/?user_id=${current_user.id}`).then((res) => {
        this.fields = res.data;
      });
      axios.get(`/api/polygon_detail/?user_id=${current_user.id}`).then((res) => {
        this.polygons = res.data;
        this.polygons.forEach((polygon) => {
          polygon.poslist.sort((a, b) => a.sequence - b.sequence);
        })
      });
    },
    zoom(){
      if (this.geoready){
        this.viewer.camera.flyTo({destination:this.Cesium.Cartesian3.fromDegrees(this.zoomX, this.zoomY, 12000.0)})
      } else {
        setTimeout(this.zoom, 100);
      }
    },
    polygonRef(polygon_id) {
      return `polygon_${polygon_id}`;
    },
    convertHierarchy(polygon) {
      return polygon.poslist.map((pos) => {
        return { "lng": pos.lng, "lat": pos.lat, height: 0 }
      });
    },

    vecCross(vector1, vector2){
      return vector1.x * vector2.y - vector1.y * vector2.x;
    },

    isCrossing(pointA1, pointA2, pointB1, pointB2){
      const vectorA = {x:pointA2.x - pointA1.x, y:pointA2.y - pointA1.y, z:0};
      const vectorB = {x:pointB2.x - pointB1.x, y:pointB2.y - pointB1.y, z:0};
      const vectorA1B1 = {x:pointB1.x - pointA1.x, y:pointB1.y - pointA1.y, z:0};
      const vectorA1B2 = {x:pointB2.x - pointA1.x, y:pointB2.y - pointA1.y, z:0};
      const vectorB1A1 = {x:pointA1.x - pointB1.x, y:pointA1.y - pointB1.y, z:0};
      const vectorB1A2 = {x:pointA2.x - pointB1.x, y:pointA2.y - pointB1.y, z:0};
      return this.vecCross(vectorA, vectorA1B1) * this.vecCross(vectorA, vectorA1B2) < 0 
        && this.vecCross(vectorB, vectorB1A1) * this.vecCross(vectorB, vectorB1A2) < 0;
    },

    getOpposeLines(array, idx) {
      const lines = [];
      for (let i = idx; i < array.length; i++) {
        if (idx == (i%array.length) || idx == ((i - 1)%array.length)) continue;
        lines.push(this.getLine(array, i));
      }
      return lines;
    },

    getLine(array, idx) {
      const index = idx%array.length;
      const next_index = (idx+1)%array.length;
      return [
        array[index],
        array[next_index],
      ];
    },

    checkPolygon(points){
      const length = points.length;
      // ポリゴンじゃなければ
      if (length < 3) return true;
      // 三角形は無条件で許可
      if (length == 3) return true;
      
      // 起点からの辺を元に全ての辺を走査
      let result = true;
      points.forEach((point, index, array) => {
        // 
        const lineA = this.getLine(array, index);
        const opposeLines = this.getOpposeLines(array, index);
        opposeLines.forEach((line)=>{
          const isCross = this.isCrossing(lineA[0], lineA[1], line[0], line[1]);
          if (isCross) {
            result = false;
          }
        });
      });

      return result;
    },
    
    toggleMeasureArea() {
      this.measuring = !this.measuring
      this.points = this.$refs['measureArea'].points.map((point)=>(point.position))

      // 終端チェック
      // 自己交差ポリゴンなら最終点を削除する
      if (!this.checkPolygon(this.points)) {
        console.log("Polygon is crossed by right-clicking.");
        this.points.pop();
      }

      if (!this.measuring) {
        this.clear();
        if (this.points.length<3) {
          return;
        }
        this.$refs['registerFieldDialog'].showModal();
      }
      // toggle measure mode
      this.$refs['measureArea'].measuring = this.measuring
    },
    clear() {
      this.$refs['measureArea'].clear()
    },
    activeEvt(_) {
      this[_.type] = _.isActive
    },
    hasPolygon(field_id) {
      const result = this.getPolygon(field_id);
      return result.length > 0;
    },
    polygonClick(polygon) {
      this.selectField = polygon.field;
      this.area = polygon.area_space;
      this.$refs['udpateFieldDialog'].showModal();
    },
    onClickUpdate(field){
      const polygon = this.getPolygon(field.id)[0];
      this.selectField = field;
      this.area = polygon.area_space;
      this.$refs['udpateFieldDialog'].showModal();
    },
    onClickDelete(field){
      const polygon = this.getPolygon(field.id)
      if (!polygon){
        console.log("undefined");
        return;
      }
      Swal.fire({
          icon: 'warning',
          title: '圃場エリアの削除',
          text: 'この圃場エリアを削除しますか？',
          showConfirmButton:true,
          showCloseButton:true
        }).then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            Promise.all([
              axios.delete(`/api/polygon/${polygon[0].id}/`),
              axios.delete(`/api/fields/${field.id}/`)
            ]).catch(()=>{
              Swal.fire({
                icon: 'warning',
                title: 'Error',
                text: 'サーバーエラーです。',
                showConfirmButton:true,
                showCloseButton:false,
                timer:3000
              })
            }).then(()=>{
              this.loading = false;
              this.reload();
              Swal.fire({
                icon: 'success',
                title: '削除完了',
                text: '削除しました',
                showConfirmButton:false,
                showCloseButton:true
              });
            });

          }
        })
    },
    measureEvt(result) {
      this.area = result.polyline.area;
    },
    movingEvt() {
    },
    getPolygon(field_id) {
      return this.polygons.filter((polygon)=>{
        return polygon.field && polygon.field.id == field_id;
      });
    },
    flyTo(field_id) {
      const result = this.getPolygon(field_id);
      const pos = result[0].poslist[0];
      this.viewer.camera.flyTo({destination:this.Cesium.Cartesian3.fromDegrees(pos.lng, pos.lat, 1000.0)});
    },
    onSelectField(field_id){
      if (this.selectFieldId == field_id){
        this.selectFieldId = "" 
      } else {
        this.selectFieldId = field_id;
      }
    },
    /// TODO to mixin
    checkLoggedIn() {
      this.$session.start();
      if (!this.$session.has("token")) {
        this.$session.destroy();
        router.push("/auth");
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `JWT ${this.$session.get("token")}`;
        axios.get('/api/current_user/').then(res => {
          this.$session.set('current_user',res.data);
        }).catch(()=>{
          this.$session.destroy();
          router.push("/auth");
        });
      }
    }
  },
  computed : {
    viewerHeight() {
      return `${this.windowSize.height-100-36}px`
    },
    contentHeight() {
      return `${this.windowSize.height-100}px`
    },
    fieldNum() {
      return this.fields.length;
    },
    displayFields() {
      return this.fields.filter((field) => {
        if (field.name && field.name.includes(this.searchFields)) {
          return true;
        }
        if (field.address && field.address.includes(this.searchFields)) {
          return true;
        }
        if (field.description && field.description.includes(this.searchFields)) {
          return true;
        }
        return false;
      });
    }
  }

};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
.selected .v-card {
  background-color: #888800 !important;
}
.viewer {
  width: 100%;
}
</style>
