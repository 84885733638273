<template>
  <v-container >
    <v-card class="d-flex flex-column my-6 mx-auto" v-if="!is_verified">
      <v-card-text class="d-flex justify-center flex-column">
        <v-card-title class="d-flex justify-center pa-0 mt-6 mb-3"
          >{{displayText}}再度メールアドレス入力からやり直してください。</v-card-title
        >
        <v-btn 
              class="primary" 
              v-on:click="onClickLogin">ログイン画面へ</v-btn>
      </v-card-text>
    </v-card>
    <v-card class="d-flex flex-column my-6 mx-auto" v-if="is_verified && !isUpdate">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-text class="d-flex justify-center flex-column">
          <v-card-title class="d-flex justify-center pa-0 mt-6 mb-3"
            >パスワード変更</v-card-title
          >
          <div class="text-center">
            <v-card-text>
                パスワードの再設定を行います。
            </v-card-text>
          </div>
          <div class="mx-9">
            <v-text-field
                type="password"
                v-model="password"
                :counter="20"
                label="新しいパスワード"
                :rules="passwordRules"
                maxlength="20"
                required
            />
            <v-text-field
                type="password"
                v-model="rePassword"
                :counter="20"
                label="パスワード確認"
                :rules="rePasswordRules"
                maxlength="20"
                required
                confirmed:password
            />

            <div class="text-center">
              <v-btn 
                class="primary" 
                :disabled="!valid"
                v-on:click="submit">変更する</v-btn>
            </div>            
          </div>
        </v-card-text>
      </v-form>
    </v-card>
    <v-card class="d-flex flex-column my-6 mx-auto" v-if="isUpdate">
      <v-card-text class="d-flex justify-center flex-column">
        <v-card-title class="d-flex justify-center pa-0 mt-6 mb-3"
          >パスワードの再設定が完了しました。</v-card-title
        >
        <v-btn 
              class="primary" 
              v-on:click="onClickLogin">ログイン画面へ</v-btn>
      </v-card-text>
    </v-card>
    <v-layout
        row
        fill-height
        justify-center
        align-center
        v-if="loading"
    >
        <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
        />
    </v-layout>

  </v-container>
</template>

<script>
import router from "../../router";
import axios from 'axios';
import Swal from 'sweetalert2';

// パスワード再設定
export default {
  name: "ResetPassword",
  components: {
  },
  mounted() {
    // tokenのvalidチェック
    // $route.params.token
    axios.get(`/api/verify_url_reset_password/${this.$route.params.token}/`).then(res => {
      this.status = res.status;
      this.is_verified = true;
    }).catch((e) => {
      this.status = e.response.status;
    });
  },
  data() {
    return {
      password:"",
      passwordRules: [
        v => !!v || "パスワードは必須です",
        v => (v && v.length > 3) || "パスワードは4文字以上でなければなりません"
      ],
      rePassword:"",
      rePasswordRules: [
        v => !!v || "パスワードは必須です",
        v => (v && v.length > 3) || "パスワードは4文字以上でなければなりません",
        v => (v == this.password) || "パスワードが一致してません。"
      ],
      message: {
        invalid: "無効なURLです。",
        expired: "URLの有効期限が切れています。",
      },
      status: '',
      valid: false,
      is_verified: false,
      loading: false,
    }
  },
  computed: {
    displayText () {
      if (this.status == 400) return this.message.invalid;
      if (this.status == 401) return this.message.expired;
      return "";
    },
  },
  methods: {
    validate () {
      return this.$refs.form.validate()
    },
    resetValidation () {
      return this.$refs.form.resetValidation()
    },
    formReset () {
      return this.$refs.form.reset()
    },
    submit() {
      if (this.validate()) {
        this.valid = false;
        this.loading = true;
        axios.post(`/api/reset_password/${this.$route.params.token}/`, {
          'password': this.password,
        }).then(() => {
          this.loading = false;
          this.formReset();
            //router.push("/auth");
            this.isUpdate = true;
        }).catch(() => {
          this.loading = false;
            Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'サーバーエラーです。',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
            })
          this.formReset();
          }
        )}
    },
    onClickLogin() {
      router.push("/auth");
    }
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
</style>
