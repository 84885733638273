<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="240px"
  >
    <v-card>
      <v-layout
          row
          fill-height
          justify-center
          align-center
      >
          <v-progress-circular
          :size="240"
          color="primary"
          indeterminate
          />
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>

// ローディング
export default {
  name: "LoadingDialog",
  components: {
  },
  props: {
  },
  mounted() {
  },
  data() {
    return {
      dialog: false
    }
  },
  computed: {
  },
  methods: {
    showModal () {
      this.dialog = true;
    },
    closeModal () {
      this.dialog = false;
    }
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
</style>
