<template>
  <v-container >
    <v-card class="d-flex flex-column my-6 mx-auto" v-if="!is_registered">
      <v-form
        ref="form"
        @submit.prevent
        v-model="valid"
        lazy-validation
      >
        <v-card-text class="d-flex justify-center flex-column">
          <v-card-title class="d-flex justify-center pa-0 mt-6 mb-3"
            >パスワードを忘れた方</v-card-title
          >
        <div class="text-center">
            <v-card-text>
                ご登録されたメールアドレスにパスワード再設定ページへのご案内を送信します。
            </v-card-text>
        </div>
          <div class="mx-9">
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="メールアドレス"
              required
            ></v-text-field>

            <div class="text-center">
              <v-btn 
                class="primary" 
                :disabled="!valid"
                v-on:click="submit">メールを送信する</v-btn>
            </div>            
          </div>
        </v-card-text>
      </v-form>
    </v-card>
    <v-card class="d-flex flex-column my-6 mx-auto" v-if="is_registered">
      <v-card-text class="d-flex justify-center flex-column">
        <v-card-title class="d-flex justify-center pa-0 mt-6 mb-3"
          >メールを送信しました。パスワードの再設定を行ってください。</v-card-title
        >
        <v-btn 
              class="primary" 
              v-on:click="onClickLogin">ログイン画面へ</v-btn>
      </v-card-text>
    </v-card>
    <v-layout
        row
        fill-height
        justify-center
        align-center
        v-if="loading"
    >
        <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
        />
    </v-layout>

  </v-container>
</template>

<script>
import router from "../../router";
import axios from 'axios';
import Swal from 'sweetalert2';

// パスワードリセットメール送信 TODO: 送信中ボタン押せない対応
export default {
  name: "SendResetPassword",
  components: {
  },
  mounted() {
  },
  data() {
    return {
      email: '',
      emailRules: [
        v => !!v || 'メールアドレス が 必要です',
        v => /.+@.+\..+/.test(v) || 'メールアドレス の形式が正しくありません',
      ],
      valid: false,
      is_registered: false,
      loading: false,
    }
  },
  methods: {
    validate () {
      return this.$refs.form.validate()
    },
    resetValidation () {
      return this.$refs.form.resetValidation()
    },
    formReset () {
      return this.$refs.form.reset()
    },
    submit() {
      if (this.validate()) {
        this.valid = false;
        this.loading = true;
        axios.post('/api/reset_password_email/', {
          'email': this.email,
        }).then(() => {
          this.loading = false;
          this.formReset();
            //router.push("/auth");
            this.is_registered = true;
        }).catch((error) => {
          this.loading = false;
          if (error.response.status == 400) {
            Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'メールアドレスが登録されていません。新規ユーザー登録をしてください。',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
            })
            this.formReset();
          } else {
            Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'サーバーエラーです。',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
            })
            this.formReset();
          }
        })
      }
    },
    onClickLogin() {
      router.push("/auth");
    }
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
</style>
