<template>
  <v-container>
    <v-card class="my-6 mx-auto">
      <v-card-text class="d-flex justify-center flex-column">
        <v-card-title class="d-flex justify-center pa-0 mt-6 mb-3">
          masters ユーザー連携一覧
        </v-card-title>
        <v-card-text>
          <CooperationUsersDialog @reload="reload" />
        </v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>日時</th>
                <th>ユーザー名</th>
                <th>住所</th>
                <th>ステータス</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="cooperation in cooperationList" :key="cooperation.id">
                <td>{{ cooperation.formatted_updated_at }}</td>
                <td>{{ cooperation[cooperation.coop_user].username }}</td>
                <td>{{ cooperation[cooperation.coop_user].address }}</td>
                <td>{{ cooperation.status_name }}</td>
                <td class="d-flex justify-space-around align-center">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" :disabled="cooperation.status !== 3">
                        <v-icon>mdi-vector-link</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item link @click.stop="onClickApprove(cooperation)">
                        <v-list-item-title>承認</v-list-item-title>
                      </v-list-item>
                      <v-list-item link @click="onClickRefuse(cooperation)">
                        <v-list-item-title>拒否</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn
                    text
                    small
                    @click.stop="onClickDeleteCooperation(cooperation)"
                  >
                    <v-icon class="reverse">mdi-delete</v-icon>
                  </v-btn>
                  <CooperationPlantMastersDialog
                    :coop_id="cooperation.id"
                    :disabled="cooperation.status === 4"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import AuthUtils from '@/utils/AuthUtils.js'
import axios from 'axios'
import Swal from 'sweetalert2';

import router from "../../router";
import CooperationPlantMastersDialog from '@/components/comps/CooperationPlantMastersDialog'
import CooperationUsersDialog from '@/components/comps/CooperationUsersDialog'
//import MessageListDialog from '@/components/comps/MessageListDialog'

export default {
  name: 'Cooperation',
  components: {
    CooperationUsersDialog,
    CooperationPlantMastersDialog,
//    MessageListDialog
  },
  mounted() {
    this.reload();
  },
  data() {
    return {
      cooperationList: [],
    }
  },
  methods: {
    checkLoggedIn() {
      this.$session.start();
      if (!this.$session.has("token")) {
        this.$session.destroy();
        router.push("/auth");
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `JWT ${this.$session.get("token")}`;
        axios.get('/api/current_user/').then(res => {
          this.$session.set('current_user',res.data);
        }).catch(()=>{
          this.$session.destroy();
          router.push("/auth");
        });
      }
    },
    reload() {
      this.checkLoggedIn()
      AuthUtils.checkAccountPermission(this.$session, router);
      axios.get('/api/coop_users/').then(res => {
        let coop_users = res.data.filter(obj => obj.coop_user == "masters_user").map(obj => {
          var date = new Date(obj.updated_at)
          obj.formatted_updated_at = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
          return obj
        })
        // tableに入れるためにデータを整形
        const status_names = [
          '連携済み',
          '申請中',
          '新しい申請',
          '却下',
        ]
        coop_users = coop_users.map(coop_user => {
          coop_user.status_name = status_names[coop_user.status - 1]
          return coop_user
        })
        this.cooperationList = coop_users
      })
    },
    onClickDeleteCooperation(cooperation) {
      if (this.$session.get('current_user').created_masters && this.$session.get('current_user').created_masters == cooperation.masters_user?.id) {
        this.openDisallowDeletionDialog(cooperation)
        return
      }
      Swal.fire({
        icon: 'warning',
        title: '連携の削除',
        text: `「${cooperation[cooperation.coop_user].username}」との連携を削除しますか？削除すると連携先からあなたの情報を閲覧することができなくなります。`,
        showConfirmButton:true,
        showCloseButton:true
      }).then((result) => {
        if(result.isConfirmed) {
          axios.delete(`/api/coop_users/${cooperation.id}/`).then(() => {
            this.reload()
          })
            .catch(() => {
              this.openDisallowDeletionDialog(cooperation)
              return
            })
        }
      })
    },
    openDisallowDeletionDialog(cooperation) {
      Swal.fire({
        icon: 'warning',
        title: 'このユーザーとの連携は削除できません',
        text: `「${cooperation.masters_user?.username}」は、あなたのアカウントを発行したユーザーなので連携の削除はできません。`
      })
    },
    onClickApprove(cooperation) {
      Swal.fire({
        icon: 'info',
        title: '連携の承認',
        text: `「${cooperation[cooperation.coop_user].username}」との連携を承認しますか？承認すると連携先からあなたの栽培グループ情報を閲覧することができます。`,
        showConfirmButton:true,
        showCloseButton:true
      }).then((result) => {
        if(result.isConfirmed) {
          axios.put(`/api/coop_users/${cooperation.id}/`, {status: 1}).then(() => {
            this.reload()
          })
        }
      })
    },
    onClickRefuse(cooperation) {
      Swal.fire({
        icon: 'warning',
        title: '連携の拒否',
        text: `「${cooperation[cooperation.coop_user].username}」との連携を拒否しますか？`,
        showConfirmButton:true,
        showCloseButton:true
      }).then((result) => {
        if(result.isConfirmed) {
          axios.put(`/api/coop_users/${cooperation.id}/`, {status: 4}).then(() => {
            this.reload()
          })
        }
      })
    },
  }
}
</script>
<style scoped>
</style>
