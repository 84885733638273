<template>
  <v-container>
    <v-card class="d-flex flex-column my-6 mx-auto">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text class="d-flex justify-center flex-column">
          <v-card-title class="d-flex justify-center pa-0 mt-6 mb-3"
            >新規ユーザー登録フォーム</v-card-title
          >
          <div class="mx-9">
            <v-text-field
              v-model="userName"
              :counter="50"
              :rules="userNameRules"
              label="ユーザー名"
              required
            ></v-text-field>

            <v-text-field
              v-model="chargeName"
              :counter="50"
              :rules="chargeNameRules"
              label="担当者氏名"
              required
            ></v-text-field>

            <v-text-field
              v-model="zipcode"
              :counter="7"
              :rules="zipcodeRules"
              label="郵便番号(ハイフン抜き7桁の数字で入力してください 例: 163-8001 => 1638001)"
              required
            ></v-text-field>

            <v-text-field
              v-model="address"
              :counter="256"
              :rules="addressRules"
              label="住所"
              required
            ></v-text-field>

            <v-text-field
              v-model="contact"
              :counter="256"
              :rules="contactRules"
              label="代表者の連絡先"
              required
            ></v-text-field>

            <v-text-field
              v-model="position"
              :counter="50"
              :rules="positionRules"
              label="役職"
              required
            ></v-text-field>

            <v-text-field
              v-model="department"
              :counter="256"
              :rules="departmentRules"
              label="所属部署"
              required
            ></v-text-field>

            <v-text-field
              type="password"
              v-model="password"
              :counter="20"
              label="パスワード"
              :rules="passwordRules"
              maxlength="20"
              required
            />

            <div class=coop_wrapper>
              <v-switch
                v-model="isMastersCoop"
                :label="`masters からの連携申請を許可する`"
              ></v-switch>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="grey" dark v-bind="attrs" v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span class>
                  ON にすると連携申請の対象となり、あなたのユーザー名と住所が masters の連携先として表示されます。
                  OFF にすると表示されません。
                  </span
                >
              </v-tooltip>
            </div>

            <v-checkbox
              v-model="isPolicyConsent"
              :rules="isPolicyConsentRules"
              required
            >
              <template v-slot:label>
                <div>
                  <a
                    target="_blank"
                    href="https://cges-statics.s3.ap-northeast-1.amazonaws.com/static/GrowthWatcher+%E3%83%95%E3%82%9A%E3%83%A9%E3%82%A4%E3%83%8F%E3%82%99%E3%82%B7%E3%83%BC%E3%83%9B%E3%82%9A%E3%83%AA%E3%82%B7%E3%83%BC.pdf"
                    @click.stop
                  >
                    プライバシーポリシー
                  </a>
                  に同意する
                </div>
              </template>
            </v-checkbox>

            <v-checkbox
              v-model="isTermsConsent"
              :rules="isTermsConsentRules"
              required
            >
              <template v-slot:label>
                <div>
                  <a
                    target="_blank"
                    href="https://cges-statics.s3.ap-northeast-1.amazonaws.com/static/GrowthWatcher%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84.pdf"
                    @click.stop
                  >
                    利用規約
                  </a>
                  に同意する
                </div>
              </template>
            </v-checkbox>

            <div class="text-center">
              <v-btn class="primary" :disabled="!valid" v-on:click="submit"
                >アカウント作成</v-btn
              >
            </div>
          </div>
        </v-card-text>
      </v-form>
    </v-card>
    <AcceptInvitationDialog
      :status="status"
      :dialog="firstDialog"
      @close-dialog="firstDialog = false"
    />
    <LoadingDialog ref="loadingDialog"> </LoadingDialog>
  </v-container>
</template>
<script>
import LoadingDialog from '@/components/comps/LoadingDialog';
import AcceptInvitationDialog from '@/components/comps/AcceptInvitationDialog';
import axios from 'axios';
import Swal from 'sweetalert2';
import router from '../../router';

export default {
  name: 'AcceptInvitation',
  components: {
    LoadingDialog,
    AcceptInvitationDialog,
  },
  mounted() {
    axios
      .get(`/api/verify_email/${this.$route.params.token}/`)
      .then((res) => {
        this.status = res.status;
        this.user_id = res.data?.user_id;
        this.firstDialog = true;
      })
      .catch((e) => {
        this.status = e.response.status;
        this.firstDialog = true;
      });
  },
  data() {
    return {
      status: null,
      firstDialog: false,
      userName: '',
      userNameRules: [
        (v) => !!v || 'ユーザー名 が 必要です',
        (v) =>
          (v && v.length > 3) || 'ユーザー名は4文字以上でなければなりません',
        //        v => /^[a-zA-Z0-9_]+$/.test(v) || "許可されていない文字が入力されています(a-zA-Z0-9_)",
        (v) =>
          (v && v.length <= 50) || 'ユーザー名 は 50文字以内で入力してください',
      ],
      chargeName: '',
      chargeNameRules: [
        (v) => !!v || '担当者名 が 必要です',
        (v) =>
          (v && v.length <= 50) || '担当者名 は 50文字以内で入力してください',
      ],
      position: '',
      positionRules: [
        (v) => !!v || '役職 が 必要です',
        (v) => (v && v.length <= 50) || '役職 は 50文字以内で入力してください',
      ],
      zipcode: '',
      zipcodeRules: [
        (v) => !!v || '郵便番号 が 必要です',
        (v) =>
          (v && v.length <= 7) || '郵便番号 は 7文字以内で入力してください',
        (v) => /[0-9]/.test(v) || '郵便番号 は 数字のみで入力してください',
      ],
      address: '',
      addressRules: [
        (v) => !!v || '住所 が 必要です',
        (v) =>
          (v && v.length <= 255) || '住所 は 255文字以内で入力してください',
      ],
      contact: '',
      contactRules: [
        (v) => !!v || '代表者の連絡先 が 必要です',
        (v) =>
          (v && v.length <= 255) ||
          '代表者の連絡先 は 255文字以内で入力してください',
      ],
      department: '',
      departmentRules: [
        (v) => !!v || '所属部署 が 必要です',
        (v) =>
          (v && v.length <= 255) || '所属部署 は 255文字以内で入力してください',
      ],
      password: '',
      passwordRules: [
        (v) => !!v || 'パスワードは必須です',
        (v) =>
          (v && v.length > 3) || 'パスワードは4文字以上でなければなりません',
      ],
      isPolicyConsent: false,
      isPolicyConsentRules: [
        (v) => !!v || 'プライバシーポリシーの同意 が 必要です',
      ],
      isTermsConsent: false,
      isTermsConsentRules: [(v) => !!v || '利用規約の同意 が 必要です'],
      user: '',
      valid: false,
      isMastersCoop: false,
    };
  },
  computed: {
    isUserTypeOther() {
      return this.userType == 'その他';
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    resetValidation() {
      return this.$refs.form.resetValidation();
    },
    formReset() {
      return this.$refs.form.reset();
    },
    submit() {
      if (this.validate()) {
        this.$refs['loadingDialog'].showModal();
        axios
          .patch(`/api/activate_invited_farmer/${this.user_id}/`, {
            username: this.userName,
            charge_name: this.chargeName,
            position: this.position,
            department: this.department,
            zipcode: this.zipcode,
            address: this.address,
            contact: this.contact,
            password: this.password,
            is_policy_consent: this.isPolicyConsent,
            is_terms_consent: this.isTermsConsent,
            is_active: true,
            is_valid_email: true,
            is_masters_open: this.isMastersCoop.toString(),
          })
          .then((res) => {
            this.user = res.data;
            this.$refs['loadingDialog'].closeModal();
            Swal.fire({
              icon: 'info',
              title: 'アカウントの作成が完了しました',
              text: 'ログイン画面に遷移します。',
              showConfirmButton: true,
              showCloseButton: false,
            }).then(() => {
              router.push('/auth');
            });
          })
          .catch(() => {
            this.$refs['loadingDialog'].closeModal();
            Swal.fire({
              icon: 'warning',
              title: 'Error',
              text: 'サーバーエラーです。',
              showConfirmButton: true,
              showCloseButton: false,
              timer: 3000,
            });
          });
      }
    },
    navigateToLogin() {
      router.push('/auth');
    },
  },
};
</script>
<style scoped>
.searchArea {
  height: 100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
.coop_wrapper {
  display: flex;
}
</style>
