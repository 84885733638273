<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-title>
          <span class="text-h5">圃場の更新</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="name"
                  :counter="50"
                  :rules="nameRules"
                  label="圃場名"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="address"
                  :counter="256"
                  :rules="addressRules"
                  label="住所"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                面積 : {{ convertDisplayArea }} a
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="description"
                  :counter="256"
                  :rules="descriptionRules"
                  label="備考"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            @click="onClickDelete"
          >
            閉じる
          </v-btn>
          <v-btn
            color="primary"
            dark
            @click="submit"
          >
            更新
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import AuthUtils from '@/utils/AuthUtils'
import axios from 'axios';
import Swal from 'sweetalert2';

// 圃場更新ダイアログ
export default {
  name: "RegisterFieldDialog",
  components: {
  },
  props: {
    points: Array(),
    Cesium: Object(),
    area: Number(),
    field: Object()
  },
  mounted() {
    this.loading = true;
  },
  data() {
    return {
      name: '',
      nameRules: [
        v => !!v || '圃場名 が 必要です',
        v => (v && v.length <= 50) || '圃場名 は 50文字以内で入力してください',
      ],
      address: '',
      addressRules: [
        v => (String(v).length <= 256) || '住所 は 256文字以内で入力してください',
      ],
      description: '',
      descriptionRules: [
        v => (String(v).length <= 256) || '備考 は 256文字以内で入力してください',
      ],
      valid: false,
      dialog: false
    }
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.field) {
          this.name = this.field.name;
          this.address = this.field.address;
          this.description = this.field.description;
        }
      }
    }
  },
  computed: {
    convertDisplayArea () {
      // to a
      return Math.round(this.area/100*10)/10;
    }
  },
  methods: {
    validate () {
      return this.$refs.form.validate()
    },
    resetValidation () {
      return this.$refs.form.resetValidation()
    },
    formReset () {
      return this.$refs.form.reset()
    },
    showModal () {
      this.dialog = true;
    },
    closeModal () {
      this.dialog = false;
    },
    submit() {
      if (this.validate()) {
        this.loading = true;
        const current_user = AuthUtils.currentUser(this.$session);
        axios.put(`/api/fields/${this.field.id}/`, {
          'name': this.name,
          'address': this.address,
          'description': this.description,
          'user_id': current_user.id
        }).then(() => {
          this.loading = false;
          this.formReset();
          this.dialog = false;
          Swal.fire({
            icon: 'info',
            title: '圃場の更新完了',
            text: '圃場の更新が完了しました',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
          this.$emit('reload')
        }).catch(() => {
          this.loading = false;
          Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'サーバーエラーです。',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
        })
      }
    },
    onClickDelete() {
      Swal.fire({
        icon: 'warning',
        title: '変更途中の退出',
        text: '変更が保存されないまま閉じられようとしていますがよろしいですか？',
        showConfirmButton:true,
        showCloseButton:true
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialog = false
        }
      })
    }
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
</style>
