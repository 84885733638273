<template>
  <v-container >
    <v-card class="d-flex flex-column my-6 mx-auto">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-text class="d-flex justify-center flex-column">
          <v-card-title class="d-flex justify-center pa-0 mt-6 mb-3"
            >{{displayText}}</v-card-title
          >
          <v-card-text class="d-flex justify-center">{{displayAnnotation}}</v-card-text>
          <div class="mx-9">
            <div class="text-center" v-if="isSuccess">
              <v-form
                ref="form"
                method="POST"
                :action="formUrl"
                v-model="valid"
              >
                <input type="hidden" name="aid" :value="aid">
                <input type="hidden" name="cod" ref="cod" required>
                <input type="hidden" name="jb" value="CAPTURE">
                <input type="hidden" name="iid" value="gwlite1">
                <input type="submit" class="hidden" ref="settlementSubmit">
              </v-form>
              <v-btn
                class="primary" 
                v-on:click.stop="onClickSettlement">支払い情報の登録へ</v-btn>
            </div>            
            <div class="text-center" v-else>
              <v-btn
                class="primary" 
                v-on:click="submit">ログイン画面へ</v-btn>
            </div>            
          </div>
        </v-card-text>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import router from "../../router";
import axios from 'axios';
import Swal from 'sweetalert2';

// ユーザー登録
export default {
  name: "VerifyEmail",
  components: {
  },
  mounted() {
    // tokenのvalidチェック
    // $route.params.token
    axios.get(`/api/verify_email/${this.$route.params.token}/`).then(res => {
      this.status = res.status;
      this.user_id = res.data?.user_id;
    }).catch((e) => {
      this.status = e.response.status;
    });
  },
  data() {
    return {
      aid: "122297",
      message: {
        valid: "Emailの確認が完了しました。支払い情報の登録を行ってください。",
        invalid: "無効なURLです。",
        expired: "URLの有効期限が切れています。",
      },
      annotation: {
        valid: `GrowthWatcher利用には月額980円(税抜き)が必要です。
        ※一ヶ月間のお試し期間つき。`,
        invalid: "再度確認メールを送信してください。",
        expired: "再度確認メールを送信してください。",
      },
      status: '',
      valid: false
    }
  },
  computed: {
    formUrl () {
      let settlement_url = process.env.VUE_APP_SETTLEMENT_URL;
      if (settlement_url) {
        return settlement_url;
      }
      return "http://rb-simulator.agriee.com:3001/link/creditcard";
    },
    displayText () {
      if (this.status == 200) return this.message.valid;
      if (this.status == 400) return this.message.invalid;
      if (this.status == 401) return this.message.expired;
      return "";
    },
    displayAnnotation () {
      if (this.status == 200) return this.annotation.valid;
      if (this.status == 400) return this.annotation.invalid;
      if (this.status == 401) return this.annotation.expired;
      return "";
    },
    isSuccess () {
      return this.status == 200 && this.user_id;
    }
  },
  methods: {
    validate () {
      return this.$refs.form.validate()
    },
    resetValidation () {
      return this.$refs.form.resetValidation()
    },
    submit() {
      router.push("/auth");
    },
    onClickSettlement() {
      axios.post(`/api/settlement_result/`, {
        'user': this.user_id,
        'prime_type': 1,
      }).then((res) => {
        const cod = res.data["cod"];

        this.$refs.cod.value = cod;
        this.$refs.settlementSubmit.click();
      }).catch(() => {
        Swal.fire({
          icon: 'warning',
          title: 'Error',
          text: 'サーバーエラーです。',
          showConfirmButton:true,
          showCloseButton:false,
          timer:3000
          })
        }
      )
    }
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
.hidden {
  display: none;
}
</style>
