<template>
  <v-container >
    <v-card class="d-flex flex-column my-6 mx-auto">
      <v-card class="d-flex justify-center flex-column my-2 gray" v-for="currentPaidUser in currentPaidUserList" :key="currentPaidUser.id">
        <v-card-title class="d-flex justify-center pa-0 mt-6 mb-3">
          {{getContractStatus(currentPaidUser.expire)}} ： {{getPrimeTypeName(currentPaidUser.prime_type)}}
          <br>
          初回決済番号:{{getRBSettlementGid(currentPaidUser)}}
          <br>
          自動課金番号:{{getRBSettlementAcid(currentPaidUser)}}
        </v-card-title>
        <v-card-actions class="d-flex justify-center">
          <v-btn :href="getEditCardUrl(currentPaidUser)">
            カード情報変更
          </v-btn>
          <v-btn :href="getStopCardUrl(currentPaidUser)">
            解約
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-form
        ref="form"
        method="POST"
        :action="formUrl"
        v-model="valid"
      >
        <v-card-card class="d-flex justify-center flex-column gray" v-if="displayPrimeTypeItemList.length > 0">
          <v-card-title class="d-flex justify-center pa-0 mt-6 mb-3">
            追加プラン
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-select
              v-model="selectPrimeType"
              :items="displayPrimeTypeItemList"
              :rules="[v => !!v || 'プランを選択してください']"
              label="プラン"
              v-on:change="onSelectPrimeType"
              required
            ></v-select>
          </v-card-actions>
          <v-card-actions class="d-flex justify-center">
            <v-btn 
              color="primary"
              dark
              @click.stop="onClickSubmit()">確認</v-btn>
          </v-card-actions>
        </v-card-card>
        <input type="hidden" name="aid" :value="aid">
        <input type="hidden" name="cod" ref="cod" required>
        <input type="hidden" name="jb" value="CAPTURE">
        <input type="hidden" name="iid" :value="selectProductCode">
        <input type="submit" class="hidden" ref="settlementSubmit">
      </v-form>
    </v-card>
    <v-card-title>
      決済一覧
    </v-card-title>
    <v-data-table
      :headers="rbSettlementHeaders"
      :items="rbAutoSettlements"
      :items-per-page="5"
      item-key="rb_settle_id"
    >
      <template v-slot:[`item.date`]="{ item }">
        <span>{{ new Date(item.date).toISOString().substr(0, 16) }}</span>
      </template>

      <template v-slot:[`item.rst`]="{ item }">
        <span>{{item.rst == 1 ? "成功" : "失敗"}}</span>
      </template>

      <template v-slot:[`item.product_name`]="{ item }">
        <span>{{getPrimeType(item.settlement_result.prime_type) ? getPrimeType(item.settlement_result.prime_type).name : ""}}</span>
      </template>

      <template v-slot:[`item.price`]="{ item }">
        <span>{{getPrimeType(item.settlement_result.prime_type) ? getPrimeType(item.settlement_result.prime_type).price * taxIncluded : ""}}</span>
      </template>

    </v-data-table>
  </v-container>
</template>

<script>
import AuthUtils from '@/utils/AuthUtils.js'
import router from "../../router";
import axios from 'axios';
import Swal from 'sweetalert2';

// 決済
export default {
  name: "Settlement",
  components: {
  },
  mounted() {
    this.checkLoggedIn();
    AuthUtils.checkAccountPermission(this.$session, router);
    AuthUtils.checkSettlementPermission(this.$session, router); // 招待ユーザーは侵入不可
    axios.get('/api/prime_type_list/').then(res => {
      this.primeTypeList = res.data;
      this.primeTypeItemList = res.data.filter((res) => {
        return res.id <= 2 // farmers のみ表示
      }).map((primeType)=>{
        return {"text": primeType.name, "value": primeType.id}
      });
    });
    axios.get('/api/settlement_result/').then(res => {
      this.settlements = res.data;
    });
    axios.get('/api/rb_settlement_result_list/').then(res => {
      this.rbSettlements = res.data;
    });
    axios.get('/api/rb_auto_settlement_result_list/').then(res => {
      this.rbAutoSettlements = res.data;
    });
  },
  data() {
    return {
      loading: false,
      selectPrimeType: "",
      cod: "",
      primeTypeItemList: [],
      aid: "122297",
      taxRate: 0.1,
      taxIncluded: 1.1,
      primeTypeList:[],
      postage: 0,
      rbSettelementResult:"",
      status: '',
      settlements: [],
      rbSettlements: [],
      rbAutoSettlements: [],
      rbSettlementHeaders: [
        { text: "日時", value: "date" },
        { text: "商品名", value: "product_name" },
        { text: "金額", value: "price" },
        { text: "決済結果", value: "rst" },
      ],
      valid: false
    }
  },
  computed: {
    formUrl () {
      let settlement_url = process.env.VUE_APP_SETTLEMENT_URL;
      if (settlement_url) {
        return settlement_url;
      }
      return "http://rb-simulator.agriee.com:3001/link/creditcard";
    },
    displayPrimeTypeItemList() {
      return this.primeTypeItemList.filter((primeTypeItem) => {
        if (!this.currentPaidUserList && this.currentPaidUserList.length < 1) return true;
        const hasPaidUserList = this.currentPaidUserList.filter((paidUser) => paidUser.prime_type == primeTypeItem.value);
        if (hasPaidUserList.length > 0) return false;
        return true;
      });
    },
    currentPaidUserList() {
      const current_user = this.$session.get('current_user');
      return current_user.paid_user_list
    },
    selectProductCode() {
      if (!this.selectPrimeType) return 0;

      return this.getPrimeType(this.selectPrimeType).product_code;
    },
    selectPrimePrice() {
      if (!this.selectPrimeType) return 0;

      return this.getPrimeType(this.selectPrimeType).price;
    },
    selectPrimeTax() {
      return this.selectPrimePrice * this.taxRate;
    },
    selectPrimeText() {
      if (!this.selectPrimeType) return "";
      return this.primeTypeItemList.filter((primeTypeItem) => primeTypeItem["value"] == this.selectPrimeType)[0]["text"];
    }
  },
  methods: {
    validate () {
      return this.$refs.form.validate()
    },
    resetValidation () {
      return this.$refs.form.resetValidation()
    },
    onSelectPrimeType () {

    },
    formReset () {
      return this.$refs.form.reset()
    },
    getPrimeType(primeTypeId) {
      if (!this.primeTypeList) return;
      return this.primeTypeList.filter((primeType) => primeType.id == primeTypeId)[0];
    },
    getPrimeTypeName(primeTypeId) {
      const primeType = this.getPrimeType(primeTypeId);
      if (primeType) return primeType.name;
      return "";
    },
    getContractStatus(expire){
      const isEnable = AuthUtils.checkExpire(expire); 
      return isEnable ? "現在契約中のプラン" : "有効期限切れのプラン";
    },
    getRBSettlement(paidUser) {
      if (this.settlements.length < 1) return {};
      if (this.rbSettlements.length < 1) return {};
      const settlement = this.settlements.filter((settlement) => settlement.user == paidUser.user && settlement.prime_type == paidUser.prime_type)[0];
      if (!settlement) return {};
      return this.rbSettlements.filter((rbsettlement) => rbsettlement.cod == settlement.cod)[0];
    },
    getRBSettlementGid(paidUser) {
      const _RBSettlement = this.getRBSettlement(paidUser);
      if (!_RBSettlement) return"";
      return _RBSettlement.gid;
    },
    getRBSettlementAcid(paidUser) {
      const _RBSettlement = this.getRBSettlement(paidUser);
      if (!_RBSettlement) return"";
      return _RBSettlement.acid;
    },
    getStopCardUrl(paidUser) {
      if (this.settlements.length < 1) return "";
      if (this.rbSettlements.length < 1) return "";
      const gid = this.getRBSettlementGid(paidUser);
      return `https://credit.j-payment.co.jp/link/creditcard/auto-charge/stop?aid=${this.aid}&tid=${gid}`;
    },
    getEditCardUrl(paidUser) {
      if (this.settlements.length < 1) return "";
      if (this.rbSettlements.length < 1) return "";
      const gid = this.getRBSettlementGid(paidUser);
      return `https://credit.j-payment.co.jp/link/creditcard/auto-charge/update?aid=${this.aid}&tid=${gid}`;
    },
    onClickSubmit (){
      this.loading = true;
      if (!this.validate()) return false;
      if (!this.selectPrimeType) return false;
      const current_user = this.$session.get('current_user');

      axios.post(`/api/settlement_result/`, {
        'user': current_user.id,
        'prime_type': this.selectPrimeType,
      }).then((res) => {
        this.loading = false;
        this.isUpdate = true;
        this.cod = res.data["cod"];

        this.$refs.cod.value = this.cod;
        this.$refs.settlementSubmit.click();
      }).catch(() => {
        this.loading = false;
          Swal.fire({
          icon: 'warning',
          title: 'Error',
          text: 'サーバーエラーです。',
          showConfirmButton:true,
          showCloseButton:false,
          timer:3000
          })
        this.formReset();
        }
      )
    },
    checkLoggedIn() {
      this.$session.start();
      if (!this.$session.has("token")) {
        this.$session.destroy();
        router.push("/auth");
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `JWT ${this.$session.get("token")}`;
        axios.get('/api/current_user/').then(res => {
          this.$session.set('current_user',res.data);
        }).catch(()=>{
          this.$session.destroy();
          router.push("/auth");
        });
      }
    },
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.gray {
  background-color: #3c3c3c;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
.hidden {
  display: none;
}
</style>
