<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{on, attrs}">
      <v-btn
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        @click="reload"
        :class="{fullWidth:fullWidth}"
      >
        連携申請
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-card-title>
              連携申請
            </v-card-title>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-card-title>
              申請先
            </v-card-title>
          </v-col>
          <v-col cols="6">
            <v-card-title>
              {{buyerName}}
            </v-card-title>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card-subtitle>
              メッセージ
            </v-card-subtitle>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card-title>
              <v-textarea v-model="message">
              </v-textarea>
            </v-card-title>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          @click="dialog = false"
          dark
        >
          閉じる
        </v-btn>
        <v-btn
          color="primary"
          dark
          @click="clickRequestButton"
        >
          申請
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2';

import router from "../../router";

export default {
  name: 'AlignmentApplicationDialog',
  props: {
    fullWidth: Boolean(),
    buyersInfo: Object()
  },
  mounted() {
    this.reload()
  },
  data() {
    return {
      dialog: false,
      message: ''
    }
  },
  methods: {
    checkLoggedIn() {
      this.$session.start();
      if (!this.$session.has("token")) {
        this.$session.destroy();
        router.push("/auth");
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `JWT ${this.$session.get("token")}`;
        axios.get('/api/current_user/').then(res => {
          this.$session.set('current_user',res.data);
        }).catch(()=>{
          this.$session.destroy();
          router.push("/auth");
        });
      }
    },
    reload() {
      this.checkLoggedIn();
      this.$emit('reload');
    },
    clickRequestButton() {
      axios.post('/api/coop_users/', {
      // axios.post('/api/buyers/coop_users/', {
        'buyers_user_id_list': [this.buyersInfo.user.id],
        'message': this.message
      }).then(() => {
        Swal.fire({
          icon: 'info',
          title: '申請完了',
          text: '申請が完了しました',
          showCloseButton: true
        }).then(() => {
          this.reload()
          this.$emit('reload')
          this.dialog = false
        })
      }).catch((err) => {
        const errorMessage = err.response?.data?.detail ? err.response.data.detail : '予期せぬエラーで申請に失敗しました'
        Swal.fire({
          icon: 'warning',
          title: '申請失敗',
          text: errorMessage,
          showCloseButton: true
        }).then(() => {
          this.reload()
        })
      })
    }
  },
  computed : {
    buyerName() {
      return this.buyersInfo ? this.buyersInfo.company_name : '';
    }
  }
}
</script>
<style scoped>
.is_cooperated {
  color: #808080;
}
.fullWidth {
  width: 100%;
}
</style>
