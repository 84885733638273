<template>
  <v-dialog v-model="dialog" max-width="600px" :persistent="status !== 200">
    <v-card v-if="status === 200">
      <v-card-title>
        Growth Watcherへのご登録ありがとうございます
      </v-card-title>
      <v-card-text>
        必要情報を入力し、アカウントの作成を完了させてください。
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog">閉じる</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else-if="status === 401">
      <v-card-title>
        URLの有効期限が切れています
      </v-card-title>
      <v-card-text>
        アカウントの作成は、招待メール送信から1週間以内に行う必要があります。お手数ですが、再度招待者様に招待メールを送信いただくようご連絡ください。
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog">閉じる</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else-if="status === 403">
      <v-card-title>
        使用済みのURLです
      </v-card-title>
      <v-card-text>
        すでにこのURLからアカウントが作成されています。
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog">閉じる</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-title>
        無効なURLです
      </v-card-title>
      <v-card-text>
        招待メールを再度ご確認いただき、有効なURLでアクセスしてください。それでもこのメッセージが表示される場合は、再度招待者様に招待メールを送信いただくようご連絡ください。
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog">閉じる</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import router from '../../router';

export default {
  name: "AcceptInvitationDialog",
  props: {
    status: {
      type: Number,
    },
    dialog: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
    }
  },
  methods: {
    closeDialog() {
      if (this.status !== 200) {
        router.push('/auth')
        return
      }
      this.$emit("close-dialog")
    }
  }
};
</script>
<style scoped></style>
