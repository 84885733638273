<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="buttonColor"
        dark
        v-bind="attrs"
        v-on="on"
        v-if="!isUpdate"
      >
        {{'栽培グループ情報の新規追加'}}
      </v-btn>
      <v-icon v-if="isUpdate" @click.stop="dialog = true">mdi-application-edit</v-icon>
    </template>
    <v-card>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-title>
          <span class="text-h5">{{isUpdate ? '栽培グループ情報の更新' : '栽培グループ情報の登録'}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-select
                  v-model="crop"
                  :items="cropItems"
                  :rules="[v => !!v || '作物が必要です']"
                  label="作物"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  :counter="256"
                  :rules="nameRules"
                  label="グループ名"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            @click="onClickDelete"
          >
            閉じる
          </v-btn>
          <v-btn
            v-if="!isUpdate"
            color="primary"
            dark
            @click="onClickCreate"
          >
            新規追加
          </v-btn>
          <v-btn
          v-if="isUpdate"
            color="primary"
            dark
            @click="onClickUpdate"
          >
            更新
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import AuthUtils from '@/utils/AuthUtils'
import axios from 'axios';
import Swal from 'sweetalert2';

// 栽培情報マスタ登録ダイアログ
export default {
  name: "PlantMasterDialog",
  components: {
  },
  props: {
    cropItems: Array(),
    plantMaster: Object()
  },
  mounted() {
  },
  data() {
    return {
      crop: '',
      name: '',
      nameRules: [
        v => !!v || 'グループ名 が 必要です',
        v => (v && v.length <= 256) || 'グループ名 は 256文字以内で入力してください',
      ],
      valid: false,
      dialog: false
    }
  },
  computed: {
    isUpdate(){
      return this.plantMaster != undefined;
    },
    buttonColor(){
      return this.isUpdate ? "" : "primary"
    }
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.plantMaster) {
          this.crop = this.plantMaster.crop.id;
          this.name = this.plantMaster.name;
        }
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.form.validate()
    },
    resetValidation () {
      return this.$refs.form.resetValidation()
    },
    formReset () {
      return this.$refs.form.reset()
    },
    onClickCreate() {
      if (this.validate()) {
        this.loading = true;
        const current_user = AuthUtils.currentUser(this.$session);
        axios.post('/api/plant_master/', {
          'crop_id': this.crop,
          'name': this.name,
          'user_id': current_user.id
        }).then(() => {
          this.loading = false;
          this.formReset();
          this.dialog = false;
          Swal.fire({
            icon: 'info',
            title: '栽培グループ情報の登録',
            text: '栽培グループ情報の登録が完了しました',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
          this.$emit('reload')
        }).catch((err) => {
          this.loading = false;
          if ("non_field_errors" in err.response.data){
            Swal.fire({
              icon: 'warning',
              title: 'Error',
              text: err.response.data["non_field_errors"][0],
              showConfirmButton:true,
              showCloseButton:false,
              timer:3000
            })
          } else {
            Swal.fire({
              icon: 'warning',
              title: 'Error',
              text: 'サーバーエラーです。',
              showConfirmButton:true,
              showCloseButton:false,
              timer:3000
            })
          }
        })
      }
    },
    onClickUpdate() {
      if (this.validate()) {
        this.loading = true;
        const current_user = AuthUtils.currentUser(this.$session);
        axios.put(`/api/plant_master/${this.plantMaster.id}/`, {
          'crop_id': this.crop,
          'name': this.name,
          'user_id': current_user.id
        }).then(() => {
          this.loading = false;
          this.formReset();
          this.dialog = false;
          Swal.fire({
            icon: 'info',
            title: '栽培グループ情報の更新',
            text: '栽培グループ情報の更新が完了しました',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
          this.$emit('reload')
        }).catch(() => {
          this.loading = false;
          Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'サーバーエラーです。',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
        })
      }
    },
    onClickDelete() {
      Swal.fire({
        icon: 'warning',
        title: '変更途中の退出',
        text: '変更が保存されないまま閉じられようとしていますがよろしいですか？',
        showConfirmButton:true,
        showCloseButton:true
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialog = false
        }
      })
    }
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
</style>
