<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="buttonColor"
        dark
        v-bind="attrs"
        v-on="on"
        v-if="!isUpdate"
      >
        {{'栽培情報の新規追加'}}
      </v-btn>
      <v-icon v-if="isUpdate" @click.stop="dialog = true">mdi-application-edit</v-icon>
    </template>
    <v-card>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-title>
          <span class="text-h5">{{isUpdate ? '栽培情報の更新' : '栽培情報の登録'}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="field"
                  :items="fieldItems"
                  :rules="[v => !!v || '圃場が必要です']"
                  label="圃場"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-menu
                  v-model="plant_date_menu"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="plantDate"
                      clearable
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      label="定植日"
                      :rules="[v => !!v || '定植日が必要です']"
                      @click:clear="plantDate = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="plantDate"
                    :allowed-dates="isAllowedPlantDates"
                    :show-current="showCurrentDates"
                    @change="onChangePlantDate"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-menu
                  v-model="harvest_date_menu"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="harvestDate"
                      clearable
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      label="収穫日（収穫予定日）※定植日の入力が先に必要です"
                      :rules="[v => !!v || '収穫日（収穫予定日）が必要です']"
                      @click:clear="harvestDate = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="harvestDate"
                    :allowed-dates="isAllowedDates"
                    :show-current="showCurrentDates"
                    @change="harvest_date_menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="is_calculate"
                  label="平年値の計算に含める"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            @click="onClickDelete"
          >
            閉じる
          </v-btn>
          <v-btn
            v-if="!isUpdate"
            color="primary"
            dark
            @click="onClickCreate"
          >
            新規追加
          </v-btn>
          <v-btn
          v-if="isUpdate"
            color="primary"
            dark
            @click="onClickUpdate"
          >
            更新
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import AuthUtils from '@/utils/AuthUtils'
import axios from 'axios';
import Swal from 'sweetalert2';

// 栽培情報情報登録ダイアログ
export default {
  name: "PlantInfoDialog",
  components: {
  },
  props: {
    plantMaster: Object(),
    fieldItems: Array(),
    groupYear: Object(),
    plantInfo: Object()
  },
  mounted() {
  },
  data() {
    return {
      field: '',
      is_calculate: false,
      plant_date_menu: false,
      harvest_date_menu: false,
      plantDate: '',
      harvestDate: '',
      valid: false,
      dialog: false
    }
  },
  computed: {
    isUpdate(){
      return this.plantInfo != undefined;
    },
    showCurrentDates(){
      return new Date(this.groupYear.date).toISOString().substr(0, 10);
    },
    buttonColor(){
      return this.isUpdate ? "" : "primary"
    }
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.plantInfo) {
          this.field = this.plantInfo.field.id;
          this.plantDate = new Date(this.plantInfo.plant_date).toISOString().substr(0, 10);
          this.harvestDate = new Date(this.plantInfo.harvest_date).toISOString().substr(0, 10);
        }
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.form.validate();
    },
    resetValidation () {
      return this.$refs.form.resetValidation();
    },
    formReset () {
      return this.$refs.form.reset();
    },
    isAllowedPlantDates(val){
      const dateVal = new Date(val);
      const startDate = new Date(this.groupYear.date);
      const maxAllowedDay = new Date(this.groupYear.date);
      maxAllowedDay.setFullYear(
        maxAllowedDay.getFullYear() + 2
      );
      maxAllowedDay.setDate(
        maxAllowedDay.getDate() - 1
      );
      console.log(startDate);
      console.log(maxAllowedDay);
      return (dateVal >= startDate) && (dateVal <= maxAllowedDay);
    },
    isAllowedDates(val){
      const dateVal = new Date(val);
      const startDate = new Date(this.plantDate);
      const maxAllowedDay = new Date(this.plantDate);
      maxAllowedDay.setFullYear(
        maxAllowedDay.getFullYear() + 1
      );
      maxAllowedDay.setDate(
        maxAllowedDay.getDate() - 1
      );
      return (dateVal >= startDate) && (dateVal <= maxAllowedDay);
    },
    onChangePlantDate (){
      this.plant_date_menu = false
      this.harvestDate = '';

    },
    onClickCreate() {
      if (this.validate()) {
        this.loading = true;
        const current_user = AuthUtils.currentUser(this.$session);
        const plantDate = new Date(this.plantDate);
        const harvestDate = new Date(this.harvestDate);
        axios.post('/api/plant_info/', {
          'plant_master_id': this.plantMaster.id,
          'field_id': this.field,
          'is_calculate': this.is_calculate,
          'plant_date': plantDate.toISOString(),
          'harvest_date': harvestDate.toISOString(),
          'group_year_id': this.groupYear.id,
          'user_id': current_user.id
        }).then(() => {
          this.loading = false;
          this.formReset();
          this.dialog = false;
          Swal.fire({
            icon: 'info',
            title: '栽培情報の登録',
            text: '栽培情報の登録が完了しました',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          });
          this.$emit('reload');
        }).catch(() => {
          this.loading = false;
          Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'サーバーエラーです。',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
        })
      }
    },
    onClickUpdate() {
      if (this.validate()) {
        this.loading = true;
        const current_user = AuthUtils.currentUser(this.$session);
        const plantDate = new Date(this.plantDate);
        const harvestDate = new Date(this.harvestDate);
        axios.put(`/api/plant_info/${this.plantInfo.id}/`, {
          'plant_master_id': this.plantMaster.id,
          'field_id': this.field,
          'plant_date': plantDate.toISOString(),
          'harvest_date': harvestDate.toISOString(),
          'group_year_id': this.groupYear.id,
          'user_id': current_user.id
        }).then(() => {
          this.loading = false;
          this.formReset();
          this.dialog = false;
          Swal.fire({
            icon: 'info',
            title: '栽培情報の更新',
            text: '栽培情報の更新が完了しました',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          });
          this.$emit('reload');
        }).catch(() => {
          this.loading = false;
          Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'サーバーエラーです。',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          })
        })
      }
    },
    onClickDelete() {
      Swal.fire({
        icon: 'warning',
        title: '変更途中の退出',
        text: '変更が保存されないまま閉じられようとしていますがよろしいですか？',
        showConfirmButton:true,
        showCloseButton:true
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialog = false
        }
      })
    }
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
</style>
