import { render, staticRenderFns } from "./PlantMaster.vue?vue&type=template&id=67bbffc3&scoped=true&"
import script from "./PlantMaster.vue?vue&type=script&lang=js&"
export * from "./PlantMaster.vue?vue&type=script&lang=js&"
import style0 from "./PlantMaster.vue?vue&type=style&index=0&id=67bbffc3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67bbffc3",
  null
  
)

export default component.exports