<template>
  <v-container >
    <v-card class="d-flex flex-column my-6 mx-auto">
      <v-form
        ref="form"
        @submit.prevent
        v-model="valid"
        lazy-validation
      >
        <v-card-text class="d-flex justify-center flex-column" v-if="!is_registerd">
          <v-card-title class="d-flex justify-center pa-0 mt-6 mb-3">
            お問い合わせ
          </v-card-title>
          <div class="mx-9">
            <v-text-field
              v-model="title"
              :counter="50"
              :rules="titleRules"
              label="タイトル"
              required
            ></v-text-field>

            <v-textarea
              v-model="contents"
              :rules="contentsRules"
              label="お問い合わせ内容"
              required
            ></v-textarea>

            <div class="text-center">
              <v-btn 
                class="primary" 
                :disabled="!valid"
                v-on:click="submit">送信</v-btn>
            </div>            
          </div>
        </v-card-text>
      </v-form>
    </v-card>

    <v-card class="d-flex flex-column my-6 mx-auto" v-if="is_registerd">
      <v-card-text class="d-flex justify-center flex-column">
        <v-card-title class="d-flex justify-center pa-0 mt-6 mb-3"
          >お問い合わせが完了しました。担当者よりご連絡いたしますので、いましばらくお待ちください。</v-card-title
        >
      </v-card-text>
    </v-card>
    <v-layout
        row
        fill-height
        justify-center
        align-center
        v-if="loading"
    >
        <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
        />
    </v-layout>
  </v-container>
</template>

<script>
import router from "../../router";
import axios from 'axios';
import Swal from 'sweetalert2';

// お問い合わせ登録
export default {
  name: "Inquiry",
  components: {
  },
  mounted() {
    this.reload();
  },
  data() {
    return {
      title: '',
      titleRules: [
        v => !!v || 'タイトル が 必要です',
        v => (v && v.length <= 50) || 'タイトル は 50文字以内で入力してください',
      ],
      contents: '',
      contentsRules: [
        v => !!v || 'お問い合わせ内容 が 必要です',
      ],
      valid: false,
      is_registerd: false,
      loading: false,
    }
  },
  computed: {
  },
  methods: {
    validate () {
      return this.$refs.form.validate()
    },
    reload() {
      this.checkLoggedIn();
      axios.get('/api/current_user/').then(res => {
        const currentUser = res.data;
        this.userName = currentUser.username;
        this.chargeName = currentUser.charge_name;
        this.position = currentUser.position;
        this.email = currentUser.email;
        this.zipcode = currentUser.zipcode;
        this.address = currentUser.address;
        this.contact = currentUser.contact;
        this.department = currentUser.department;
      })
    },
    resetValidation () {
      return this.$refs.form.resetValidation()
    },
    formReset () {
      return this.$refs.form.reset()
    },
    checkLoggedIn() {
      this.$session.start();
      if (!this.$session.has("token")) {
        this.$session.destroy();
        router.push("/auth");
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `JWT ${this.$session.get("token")}`;
        axios.get('/api/current_user/').then(res => {
          this.$session.set('current_user',res.data);
        }).catch(()=>{
          this.$session.destroy();
          router.push("/auth");
        });
      }
    },
    submit() {
      if (this.validate()) {
        const currentUser = this.$session.get('current_user');
        this.loading = true;
        this.valid = false;
        axios.post('/api/create_inquiry/', {
          'user_id': currentUser.id,
          'title': this.title,
          'contents': this.contents,
        }).then(() => {
          this.is_registerd = true;
          this.formReset();
          this.loading = false;
        }).catch(() => {
            this.loading = false;
            Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'サーバーエラーです。',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
            })
        })
      }
    }
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
</style>
