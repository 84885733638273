<template>
  <v-dialog v-model="dialog" max-width="600px" persistent no-click-animation>
    <v-card>
      <v-card-title>
        Growth Watcherへのご登録ありがとうございます
      </v-card-title>
      <v-card-text>
        公開情報の設定を行うことで、実需者から連携申請を受けられます。<br />
        情報を公開し、設定を行いますか？<br />
        ※この設定はユーザー情報からいつでも変更できます。
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog">閉じる</v-btn>
        <v-btn color="primary" @click="setFarmersInfo">公開情報の設定へ</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import router from "../../router";
import axios from 'axios';

export default {
  name: "SetFarmersInfoDialog",
  props: {
    dialog: {
      type: Boolean,
    }
  },
  data() {
    return {
    };
  },
  methods: {
    setFarmersInfo(){
      const currentUser = this.$session.get("current_user");
      axios
        .put(`/api/users/${currentUser.id}/`, {
          email: currentUser.email,
          username: currentUser.username,
          is_info_checked: true,
          is_buyers_open: true,
        })
        .then(() => {
          router.push("/farmersInfo");
        });
    },
    closeDialog() {
      const currentUser = this.$session.get("current_user");
      axios
        .put(`/api/users/${currentUser.id}/`, {
          email: currentUser.email,
          username: currentUser.username,
          is_info_checked: true
        })
        .then(() => {
          this.$emit("close-dialog")
        });
    },
  },
};
</script>
<style scoped></style>
