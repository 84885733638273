<template>
  <v-app>
    <v-app-bar
        app
        dark
        v-if="isMenu"
      >
      <v-badge
        v-model="isMessage"
        value=" "
        content=" "
        color="red"
        offset-y="20"
        offset-x="20"
        right
      >
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      </v-badge>
      <v-toolbar-title>GrowthWatcher</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title v-if="isMenu">{{displayName()}}</v-toolbar-title>
    </v-app-bar>
    <!-- ここから -->
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group>
          <v-list-item @click.stop="selectItem('')">
            <v-list-item-title>栽培グループ</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isPermission()" @click.stop="selectItem('registerField')">
            <v-list-item-title>圃場を登録・編集する</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isUser()" @click.stop="selectItem('userInfo')">
            <v-list-item-title>ユーザー情報</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isUser()" @click.stop="selectItem('accountList')">
            <v-list-item-title>アカウント一覧</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isPermission()" @click.stop="selectItem('plantMaster')">
            <v-list-item-title>栽培グループを登録・編集する</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isUser() && !isInvitedUser()" @click.stop="selectItem('settlement')">
            <v-list-item-title>プラン変更・決済情報</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isUser()" @click.stop="selectItem('cooperation')">
            <v-list-item-title>for masters 連携</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isUser()" @click.stop="selectItem('buyersMap')">
            <v-list-item-title>for buyers マップ</v-list-item-title>
          </v-list-item>
          <v-list-item @click.stop="selectItem('coopUser')">
            <v-list-item-title>
              <v-badge
                  v-model="isMessage"
                  value=" "
                  content=" "
                  color="red"
                  right
                >
                for buyers 連携
              </v-badge>
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click.stop="selectItem('inquiry')">
            <v-list-item-title>お問い合わせ</v-list-item-title>
          </v-list-item>
          <v-list-item @click.stop="logout()">
            <v-list-item-title>ログアウト</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view @readed="readed"/>
    </v-main>
  </v-app>
</template>

<script>
import router from "./router";
import AuthUtils from './utils/AuthUtils.js';
import axios from "axios";

export default {
  name: 'App',
  mounted(){
    this.isMenu = this.$session.has("token");
    const currentUser = this.$session.get('current_user');
    axios.get(`/api/message/?user_id=${currentUser.id}`).then(res => {
      this.messageList = res.data;
    });
  },
  data(){
    return {
      drawer: false,
      isMenu: false,
      messageList: [],
      currentUser: {}
    }
  },
  watch: {
    $route() {
      this.isMenu = this.$session.has("token");
    }
  },
  methods: {
    selectItem(path){
      this.drawer = false;
      router.push(`/${path}`).catch(()=>{
        location.reload();
      });
    },
    readed() {
      const currentUser = this.$session.get('current_user');
      axios.get(`/api/message/?user_id=${currentUser.id}`).then(res => {
        this.messageList = res.data;
      });
    },
    logout(){
      this.$session.destroy();
      router.push("/auth");
    },
    // computedで再計算されないため
    isPermission() {
      return AuthUtils.isPermission(this.$session);
    },
    isUser() {
      return AuthUtils.isUser(this.$session);
    },
    isInvitedUser() {
      return AuthUtils.isInvitedUser(this.$session);
    },
    displayName() {
      const currentUser = this.$session.get("current_user");
      if (!currentUser) return '';
      return `${currentUser.username}`;
    }
  },
  computed: {
    isMessage() {
      const currentUser = this.$session.get('current_user');
      console.log(currentUser);
      console.log(this.messageList.filter((message) => message.send_user.id != currentUser.id && !message.readed_at));
      return this.messageList.filter((message) => message.send_user.id != currentUser.id && !message.readed_at).length > 0;
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
