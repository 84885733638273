<template>
  <v-menu v-model="menu" min-width="640" :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on">
        <v-icon>mdi-tune</v-icon>
      </v-btn>
    </template>
    <v-card class="pa-5">
      <v-row justify="end">
        <v-icon @click.stop="menu = false">mdi-close</v-icon>
      </v-row>
      <v-row align="center">
        <v-col cols="3" class="d-flex">
          <div class="text-subtitle-1 mr-5">検索ワード</div>
          <v-tooltip right>
            <template v-slot:activator="{on, attrs}">
              <v-icon v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
            </template>
            <span>社名、住所、栽培作物で検索できます</span>
          </v-tooltip>
        </v-col>
        <v-col cols="9">
          <v-text-field v-model="searchWord" label="検索ワード" required></v-text-field>
        </v-col>
        <v-col cols="3">
          <div class="text-subtitle-1">新規取引先獲得に向けた意欲</div>
        </v-col>
        <v-col cols="3">
          <v-checkbox
            label="低"
            v-model="desire"
            hide-details
            :value="1"
          ></v-checkbox>
        </v-col>
        <v-col cols="3">
          <v-checkbox
            label="中"
            v-model="desire"
            hide-details
            :value="2"
          ></v-checkbox>
        </v-col>
        <v-col cols="3">
          <v-checkbox
            label="高"
            v-model="desire"
            hide-details
            :value="3"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="clearButtonHandler">クリア</v-btn>
        <v-btn color="primary" @click="searchButtonHandler">検索</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  data() {
    return {
      menu: false,
      searchWord: '',
      desire: [1, 2, 3],
    }
  },
  methods: {
    searchButtonHandler() {
      if (this.desire.length === 0) {
        return alert('「新規取引先獲得に向けた意欲」は1つ以上チェックする必要があります。')
      }
      const searchWord = this.searchWord
      const desire = this.desire
      this.$emit('searchBuyers', {searchWord, desire})
      this.menu = false
    },
    clearButtonHandler() {
      this.desire = [1, 2, 3]
      this.searchWord = ''
      const searchWord = this.searchWord
      const desire = this.desire
      this.$emit('searchBuyers', {searchWord, desire})
      this.menu = false
    }
  }
}
</script>
<style scoped>

</style>
