<template>
  <v-container fluid >
    <v-row align-content="center">
      <v-col :cols="infoCols" v-if="infoCols != 0">
        <v-row>
          <v-col cols="12">
            <v-img v-if="imagePath" style="margin: 0 auto;" :src="imagePath" max-height="140" max-width="240" contain alt="圃場画像"></v-img>
            <v-img v-if="!imagePath" style="margin: 0 auto;" src="/static/no_image.png" max-height="140" max-width="240" contain alt="圃場画像"></v-img>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="company_name"
              :counter="256"
              label="会社名"
              required
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="address"
              :counter="256"
              label="住所"
              required
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="strength"
              :counter="256"
              label="アピールポイント"
              required
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="desire"
              :counter="256"
              label="新規取引先獲得に向けた意欲"
              required
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="text-md-center">
            <Align
            
            mentApplicationDialog :buyersInfo="visibleBuyersInfoList[this.currentMidx]" fullWidth="true" @reload="reload" />
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="mapCols">
        <v-row justify="end">
          <FilterMenu @searchBuyers="searchBuyers"/>
        </v-row>

        <v-row>
          <GmapMap
            :center="{lat:31.973869647255, lng:131.39836114794}"
            :zoom="7"
            :options="{streetViewControl: false}"
            map-type-id="terrain"
            style="width: 100%; height: 560px"
          >
            <GmapInfoWindow
              :options="infoOptions"
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick="toggleInfoWindow(visibleBuyersInfoList[currentMidx], currentMidx)"
            >
              <div style="min-height: 120px;">
                <v-img v-if="imagePath" :src="imagePath" max-height="140" max-width="240" contain alt="圃場画像"></v-img>
                <v-img v-if="!imagePath" src="/static/no_image.png" max-height="140" max-width="240" contain alt="圃場画像"></v-img>
                &nbsp;
              </div>
              <div class="text-h5">{{ company_name }}</div>
              <div class="text-subtitle-1">{{ address }}</div>
              <div class="text-subtitle-2">{{ strength }}</div>
              <div class="text-md-center">
                <AlignmentApplicationDialog :buyersInfo="visibleBuyersInfoList[this.currentMidx]" @reload="reload" />
              </div>
            </GmapInfoWindow>
            <GmapMarker
              v-for="(buyersInfo, idx) in visibleBuyersInfoList"
              :key="buyersInfo.id"
              :clickable="true"
              :icon="markerIcon"
              @click="toggleInfoWindow(buyersInfo, idx)"
              :position="getPosition(buyersInfo, idx)" />
          </GmapMap>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {gmapApi} from 'vue2-google-maps'
import router from "../../router";
import axios from 'axios';
import Fuse from 'fuse.js'
import FilterMenu from '@/components/comps/FilterMenu'
import AlignmentApplicationDialog from '@/components/comps/AlignmentApplicationDialog'

let fuse;

export default {
  name: "BuyersMap",
  components: {
    FilterMenu,
    AlignmentApplicationDialog,
  },
  async mounted() {
    await this.reload();
    this.visibleBuyersInfoList = JSON.parse(JSON.stringify(this.buyersInfoList))
  },
  data() {
    return {
      menu: false,
      place: null,
      buyersInfoList: [],
      visibleBuyersInfoList: [],
      currentMidx: null,
      infoOptions: {
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -50
        }
      },
      infoWindowPos: {
        lat: 32.973869647255,
        lng: 131.39836114794
      },
      infoWinOpen: false,
      markerIcon: {
        url:"/static/marker.png",
        size: {width: 50, height: 50, f: 'px', b: 'px',},
        scaledSize: {width: 50, height: 50, f: 'px', b: 'px',},
      }
    }
  },
  methods: {
    async reload() {
      this.checkLoggedIn();
      await axios.get(`/api/buyersInfo/`).then((res) => {
        this.buyersInfoList = res.data.map((buyersInfo) => {
          buyersInfo["open"] = false;
          return buyersInfo;
        });
        fuse = new Fuse(this.buyersInfoList, {
          keys: [
            'company_name',
            'user.address',
          ]
        })
        this.geoready = true;
      });
    },
    setPlace(place) {
      this.place = place
    },
    getPosition(buyersInfo) {
      return {
        lat: Number(buyersInfo.user.latitude),
        lng: Number(buyersInfo.user.longitude)
      };
    },
    toggleInfoWindow (buyersInfo, idx) {
      this.infoWindowPos = this.getPosition(buyersInfo);

      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
        this.currentMidx = null;
      }
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    checkLoggedIn() {
      this.$session.start();
      if (!this.$session.has("token")) {
        this.$session.destroy();
        router.push("/auth");
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `JWT ${this.$session.get("token")}`;
        axios.get('/api/current_user/').then(res => {
          this.$session.set('current_user',res.data);
        }).catch(()=>{
          this.$session.destroy();
          router.push("/auth");
        });
      }
    },
    searchBuyers({searchWord, desire}) {
      this.infoWinOpen = false
      this.currentMidx = null
      if (searchWord === '') {
        this.visibleBuyersInfoList = JSON.parse(JSON.stringify(this.buyersInfoList))
      } else {
        this.visibleBuyersInfoList = fuse.search(searchWord).map(obj => {
          return obj.item
        })
      }

      this.visibleBuyersInfoList = this.visibleBuyersInfoList.filter(info => {
        return desire.includes(info.desire)
      })
    }
  },
  computed : {
    google: gmapApi,
    infoCols () {
      if (this.infoWinOpen){
        return 3;
      }
      return 0;
    },
    mapCols () {
      if (this.infoWinOpen) {
        return 9;
      }
      return 12;
    },
    company_name () {
      return this.currentMidx != null ? this.visibleBuyersInfoList[this.currentMidx].company_name : '';
    },
    address () {
      return this.currentMidx != null ? this.visibleBuyersInfoList[this.currentMidx].user.address : '';
    },
    strength () {
      return this.currentMidx != null ? this.visibleBuyersInfoList[this.currentMidx].strength : '';
    },
    desire () {
      if (this.currentMidx == null) {
        return '';
      }
      const desire = this.visibleBuyersInfoList[this.currentMidx].desire;
      switch (desire) {
      case 1:
        return '低';
      case 2:
        return '中';
      case 3:
        return '高';
      }
      return '';
    },
    imagePath () {
      return this.currentMidx != null ? this.visibleBuyersInfoList[this.currentMidx].image : '';
    }
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
.text-h5, .text-subtitle-1, .text-subtitle-2 {
  color: rgba(0, 0, 0, 0.87);
}


.hide {
  display:none;
}
.plantInfo.selected .v-card {
  background-color: #888800 !important;
}
.v-card .v-badge {
  display: block;
}
.viewer {
  width: 100%;
}
</style>
