<template>
  <v-container>
    <v-card class="d-flex flex-column my-6 mx-auto">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text class="d-flex justify-center flex-column">
          <v-card-title class="d-flex justify-center pa-0 mt-6 mb-3"
            >公開情報</v-card-title
          >
          <div class="mx-9">
            <v-text-field
              v-model="orgName"
              :counter="256"
              :rules="orgNameRules"
              label="組織名"
            ></v-text-field>

            <v-file-input
              v-model="image"
              label="画像アップロード"
              accept="image/*"
            ></v-file-input>

            <v-img
              :src="imagePath"
              :width="200"
              cover
            ></v-img>
            <div class="text-center">
              <v-btn v-if="imagePath" class="red" v-on:click="deleteImage"
                >画像を削除</v-btn
              >
            </div>

            <span>位置情報(マーカーをドラッグアンドドロップすることで位置を調整できます)</span>
            <GmapMap ref="MapRef"
              :center="{lat:lat, lng:lng}"
              :zoom="7"
              map-type-id="terrain"
              style="width: 100%; height: 300px"
            >
              <GmapMarker
                :position="{lat:lat, lng:lng}"
                :icon="markerIcon"
                :draggable="true"
                @dragend="setLatLng($event)"
              />
            </GmapMap>

            <v-radio-group v-model="desire" :rules="desireRules" label="取引先獲得意欲">
              <v-radio label="高" value="3"></v-radio>
              <v-radio label="中" value="2"></v-radio>
              <v-radio label="低" value="1"></v-radio>
            </v-radio-group>

            <v-data-table
              :headers="cultivatedCropHeaders"
              :items="cultivatedCrop"
              :items-per-page="5"
              item-key="id"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>栽培情報</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <CultivatedCropDialog
                    ref="cultivatedCropDialog"
                    @reload="reload"
                    :farmersInfoId="farmersInfoId"
                  >
                  </CultivatedCropDialog>
                </v-toolbar>
              </template>
              <template v-slot: [`item.actions`]="{ item }">
                <CultivatedCropDialog
                  ref="cultivatedCropDialog"
                  @reload="reload"
                  :cultivatedCrop="item"
                >
                </CultivatedCropDialog>
                <v-btn icon>
                  <v-icon @click.stop="onClickDelete(item)">mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>

            <v-text-field
              v-model="shippingMethod"
              label="配送方法"
            ></v-text-field>

            <v-text-field v-model="paymentDate" label="支払い日"></v-text-field>

            <v-text-field
              v-model="strength"
              label="アピールポイント"
            ></v-text-field>

            <v-text-field v-model="remark" label="備考"></v-text-field>

            <div class="text-center">
              <v-btn v-if="isUpdate" class="primary" :disabled="!valid || loading" v-on:click="update"
                >更新</v-btn
              >
              <v-btn v-if="!isUpdate" class="primary" :disabled="!valid || loading" v-on:click="submit"
                >作成</v-btn
              >
            </div>
          </div>
        </v-card-text>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import {gmapApi} from 'vue2-google-maps'
import CultivatedCropDialog from "@/components/comps/CultivatedCropDialog";
import AuthUtils from "@/utils/AuthUtils.js";
import router from "../../router";
import axios from "axios";
import Swal from "sweetalert2";

// ユーザー登録
export default {
  name: "FarmersInfo",
  components: {
    CultivatedCropDialog,
  },
  computed: {
    google: gmapApi,
  },
  mounted() {
    this.reload();
  },
  data() {
    return {
      farmersInfoId: "",
      orgName: "",
      orgNameRules: [
        (v) =>
          (v && v.length <= 256) || "組織名 は 256文字以内で入力してください",
      ],
      image: null,
      imagePath: "",
      // デフォルトで東京都に設定
      lat: 35.6762,
      lng: 139.6503,
      desire: "",
      desireRules: [
        v => !!v || '取得先獲得意欲 の設定が必要です',
      ],
      shippingMethod: "",
      paymentDate: "",
      strength: "",
      remark: "",
      cultivatedCropHeaders: [
        { text: "作物", value: "name" },
        { text: "出荷時期", value: "shipping_period" },
        { text: "出荷量", value: "shipment" },
        { text: "栽培規模", value: "scale" },
        { text: "参考価格", value: "price" },
        { text: "", value: "actions", sortable: false },
      ],
      cultivatedCrop: [],
      valid: false,
      isUpdate: false,
      loading: false,
      markerIcon: {
        url:"/static/marker.png",
        size: {width: 50, height: 50, f: 'px', b: 'px',},
        scaledSize: {width: 50, height: 50, f: 'px', b: 'px',},
      }
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reload() {
      this.checkLoggedIn();
      AuthUtils.checkAccountPermission(this.$session, router);
      const currentuser = this.$session.get("current_user");
      Promise.all([
        axios
          .get(`/api/farmers_info/?user_id=${currentuser.id}`)
          .then((res) => {
            if (res.data[0]) {
              this.isUpdate = true;
              res = res.data[0];
              (this.farmersInfoId = res.id),
                (this.orgName = res.organization_name),
                (this.imagePath = res.image),
                (this.lat = res.user.latitude ? res.user.latitude : 35.6762),
                (this.lng = res.user.longitude ? res.user.longitude : 139.6503),
                (this.desire = res.desire.toString()),
                (this.shippingMethod = res.shipping_method),
                (this.paymentDate = res.payment_date),
                (this.strength = res.strength),
                (this.remark = res.remark);
            }
          }),
      ]).then(() => {
        if (this.isUpdate) {
          axios
            .get('/api/crops/')
            .then((res) => {
              this.cultivatedCrop = res.data;
            });
        } else {
          axios.get(`/api/geoapi_from_postal/?zipcode=${currentuser.zipcode}`).then((res) => {
            this.lat = Number(res.data.y);
            this.lng = Number(res.data.x);
            if (this.lat === null || this.lng === null) {
              this.lat = 35.6762,
              this.lng = 139.6503
            }
          })
        }
      });
    },
    resetValidation() {
      return this.$refs.form.resetValidation();
    },
    checkLoggedIn() {
      this.$session.start();
      if (!this.$session.has("token")) {
        this.$session.destroy();
        router.push("/auth");
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `JWT ${this.$session.get("token")}`;
        axios
          .get("/api/current_user/")
          .then((res) => {
            this.$session.set("current_user", res.data);
          })
          .catch(() => {
            this.$session.destroy();
            router.push("/auth");
          });
      }
    },
    setLatLng(event) {
      this.lat = event.latLng.lat();
      this.lng = event.latLng.lng();
    },
    update() {
      if (this.validate()) {
        this.loading = true;
        const currentuser = this.$session.get("current_user");
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
        let formData = new FormData();
        if (this.image) {
          formData.append("image", this.image);
        }
        formData.append("id", this.farmersInfoId);
        formData.append("organization_name", this.orgName);
        formData.append("latitude", this.lat);
        formData.append("longitude", this.lng);
        formData.append("desire", this.desire);
        formData.append("shipping_method", this.shippingMethod);
        formData.append("payment_date", this.paymentDate);
        formData.append("strength", this.strength);
        formData.append("remark", this.remark);
        formData.append("user_id", currentuser.id);

        axios
          .put(`/api/farmers_info/${this.farmersInfoId}/`, formData, config)
          .then(() => {
            this.loading = false;
            Swal.fire({
              icon: "info",
              title: "公開情報の更新",
              text: "公開情報の更新が完了しました",
              showConfirmButton: true,
              showCloseButton: false,
              timer: 3000,
            }).then(() => {
              this.reload();
            });
          })
          .catch(() => {
            this.loading = false;
            Swal.fire({
              icon: "warning",
              title: "Error",
              text: "サーバーエラーです。",
              showConfirmButton: true,
              showCloseButton: false,
              timer: 3000,
            });
          });
      }
    },
    submit() {
      if (this.validate()) {
        this.loading = true;
        const currentuser = this.$session.get("current_user");
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
        let formData = new FormData();
        if (this.image) {
          formData.append("image", this.image);
        }
        formData.append("id", this.farmersInfoId);
        formData.append("organization_name", this.orgName);
        formData.append("latitude", this.lat);
        formData.append("longitude", this.lng);
        formData.append("desire", this.desire);
        formData.append("shipping_method", this.shippingMethod);
        formData.append("payment_date", this.paymentDate);
        formData.append("strength", this.strength);
        formData.append("remark", this.remark);
        formData.append("user_id", currentuser.id);

        axios
          .post(`/api/farmers_info/`, formData, config)
          .then(() => {
            this.loading = false;
            Swal.fire({
              icon: "info",
              title: "公開情報の作成",
              text: "公開情報の作成が完了しました",
              showConfirmButton: true,
              showCloseButton: false,
              timer: 3000,
            }).then(() => {
              this.reload();
            });
          })
          .catch(() => {
            this.loading = false;
            Swal.fire({
              icon: "warning",
              title: "Error",
              text: "サーバーエラーです。",
              showConfirmButton: true,
              showCloseButton: false,
              timer: 3000,
            });
          });
      }
    },
    deleteImage() {
      Swal.fire({
        icon: "warning",
        title: "画像の削除",
        text: "画像を削除しますか？",
        showConfirmButton: true,
        showCloseButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const currentuser = this.$session.get("current_user");
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          }
          let formData = new FormData();
          formData.append("id", this.farmersInfoId);
          formData.append("organization_name", this.orgName);
          formData.append("desire", this.desire);
          formData.append("user_id", currentuser.id);
          formData.append("image", []);
          axios.put(`/api/farmers_info/${this.farmersInfoId}/`, formData, config
          ).then(() => {
            this.reload();
            Swal.fire({
              icon: "success",
              title: "削除完了",
              text: "削除しました",
              showConfirmButton: false,
              showCloseButton: true,
              timer: 3000,
            });
          });
        }
      });
    },
    onClickDelete(item) {
      Swal.fire({
        icon: "warning",
        title: "栽培情報の削除",
        text: "栽培情報を削除すると作物シート及び、栽培情報も削除されます。この栽培情報を削除しますか？",
        showConfirmButton: true,
        showCloseButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`/api/crops/${item.id}`).then(() => {
            this.reload();
            Swal.fire({
              icon: "success",
              title: "削除完了",
              text: "削除しました",
              showConfirmButton: false,
              showCloseButton: true,
              timer: 3000,
            });
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.searchArea {
  height: 100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
.theme--dark.v-image {
  margin: auto;
}
</style>
