<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="buttonColor"
        dark
        v-bind="attrs"
        v-on="on"
        v-if="!isUpdate"
      >
        {{ "追加" }}
      </v-btn>
      <v-icon v-if="isUpdate" @click.stop="dialog = true"
        >mdi-application-edit</v-icon
      >
    </template>
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title>
          <span class="text-h5">栽培情報の登録</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  :counter="256"
                  :rules="nameRules"
                  label="作物名"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="nameKana"
                  :counter="256"
                  :rules="nameKanaRules"
                  label="フリガナ"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="shippingPeriod"
                  label="出荷時期"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="shipment"
                  label="出荷量"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="scale"
                  label="栽培規模"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="price"
                  label="参考価格"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" @click="dialog = false"> 閉じる </v-btn>
          <v-btn
            color="primary"
            dark
            @click="onClickUpdate"
            v-if="isUpdate"
            :disabled="loading"
          >
            更新
          </v-btn>
          <v-btn
            color="primary"
            dark
            @click="onClickCreate"
            v-if="!isUpdate"
            :disabled="loading"
          >
            新規追加
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import AuthUtils from '@/utils/AuthUtils'

export default {
  name: "CultivatedCropDialog",
  components: {},
  props: {
    cultivatedCrop: Object(),
  },
  mounted() {},
  data() {
    return {
      id: "",
      name: "",
      nameRules: [
        (v) =>
          (v && v.length <= 50) ||
          "作物名 は 256文字以内で入力してください",
      ],
      nameKanaRules: [
        (v) =>
          (v && v.length <= 50) ||
          "フリガナ は 50文字以内で入力してください",
        (v) =>
          (/^[ァ-ンヴー]*$/.test(v)) ||
          "フリガナは全角カタカナで入力してください"
      ],
      nameKana: "",
      shippingPeriod: "",
      shipment: "",
      scale: "",
      price: "",
      loading: false,
      valid: false,
      dialog: false,
    };
  },
  computed: {
    isUpdate() {
      return this.cultivatedCrop != undefined;
    },
    buttonColor() {
      return "primary";
    },
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (this.cultivatedCrop) {
          this.id = this.cultivatedCrop.id;
          this.name = this.cultivatedCrop.name;
          this.nameKana = this.cultivatedCrop.name_kana;
          this.shippingPeriod = this.cultivatedCrop.shipping_period;
          this.shipment = this.cultivatedCrop.shipment;
          this.scale = this.cultivatedCrop.scale;
          this.price = this.cultivatedCrop.price;
        }
      }
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    resetValidation() {
      return this.$refs.form.resetValidation();
    },
    formReset() {
      this.$refs.form.reset();
      this.shippingPeriod = "";
      this.shipment = "";
      this.scale = "";
      this.price = "";
    },
    showModal() {
      this.dialog = true;
    },
    closeModal() {
      this.$emit("close");
      this.dialog = false;
    },
    onClickUpdate() {
      if (this.validate()) {
        const cultivatedCropData = {
          id: this.id,
          name: this.name,
          name_kana: this.nameKana,
          shipping_period: this.shippingPeriod,
          shipment: this.shipment,
          scale: this.scale,
          price: this.price,
        };
        this.loading = true;
        axios
          .patch(`/api/crops/${this.id}/`, cultivatedCropData)
          .then(() => {
            this.$emit("reload");
            this.formReset();
            this.loading = false;
            Swal.fire({
              icon: "info",
              title: "栽培情報の更新",
              text: "栽培情報の更新が完了しました",
              showConfirmButton: true,
              showCloseButton: false,
              timer: 3000,
            });
            this.closeModal();
          })
          .catch(() => {
            this.loading = false;
              Swal.fire({
                icon: "warning",
                title: "Error",
                text: "サーバーエラーが発生しました",
                showConfirmButton: false,
                showCloseButton: true,
                timer: 3000,
              });
          });
      }
    },
    onClickCreate() {
      if (this.validate()) {
        const current_user = AuthUtils.currentUser(this.$session);
        const cultivatedCropData = {
          user: current_user.id,
          name: this.name,
          name_kana: this.nameKana,
          shipping_period: this.shippingPeriod,
          shipment: this.shipment,
          scale: this.scale,
          price: this.price,
        };
        this.loading = true;
        axios
          .post(`/api/crops/`, cultivatedCropData)
          .then(() => {
            this.loading = false;
            this.$emit("reload");
            Swal.fire({
              icon: "info",
              title: "栽培情報の作成",
              text: "栽培情報の作成が完了しました",
              showConfirmButton: true,
              showCloseButton: false,
              timer: 3000,
            });
            this.formReset();
            this.closeModal();
          })
          .catch((err) => {
            console.log(err)
            this.loading = false;
              Swal.fire({
                icon: "warning",
                title: "Error",
                text: "サーバーエラーが発生しました",
                showConfirmButton: false,
                showCloseButton: true,
                timer: 3000,
              });
          });
      }
    },
  },
};
</script>

<style scoped>
.searchArea {
  height: 100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
</style>
