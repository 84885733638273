import Vue from 'vue'
import Router from 'vue-router'

import Auth from '@/components/pages/Auth'
import Top from '@/components/pages/Top'
import RegisterField from '@/components/pages/RegisterField'
import RegisterUser from '@/components/pages/RegisterUser'
import PlantMaster from '@/components/pages/PlantMaster'
import UserInfo from '@/components/pages/UserInfo'
import FarmersInfo from '@/components/pages/FarmersInfo'
import AccountList from '@/components/pages/AccountList'
import VerifyEmail from '@/components/pages/VerifyEmail'
import SendResetPassword from '@/components/pages/SendResetPassword'
import ResetPassword from '@/components/pages/ResetPassword'
import Inquiry from '@/components/pages/Inquiry'
import Settlement from '@/components/pages/Settlement'
import Cooperation from '@/components/pages/Cooperation'
import BuyersMap from '@/components/pages/BuyersMap'
import CoopUser from '@/components/pages/CoopUser'
import AcceptInvitation from '@/components/pages/AcceptInvitation'
import SendReVerifyEmail from '@/components/pages/SendReVerifyEmail'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Top',
      component: Top
    },
    {
      path: '/registerField',
      name: 'RegisterField',
      component: RegisterField
    },
    {
      path: '/plantMaster',
      name: 'PlantMaster',
      component: PlantMaster
    },
    {
      path: '/auth',
      name: 'Auth',
      component: Auth
    },
    {
      path: '/accountList',
      name: 'AccountList',
      component: AccountList
    },
    {
      path: '/userInfo',
      name: 'UserInfo',
      component: UserInfo
    },
    {
      path: '/farmersInfo',
      name: 'FarmersInfo',
      component: FarmersInfo
    },
    {
      path: '/registerUser',
      name: 'RegisterUser',
      component: RegisterUser
    },
    {
      path: '/verifyEmail/:token',
      name: 'VerifyEmail',
      component: VerifyEmail
    },
    {
      path: '/sendResetPassword',
      name: 'SendResetPassword',
      component: SendResetPassword
    },
    {
      path: '/resetPassword/:token',
      name: 'ResetPassword',
      component: ResetPassword
    },
    {
      path: '/inquiry',
      name: 'Inquiry',
      component: Inquiry
    },
    {
      path: '/settlement',
      name: 'Settlement',
      component: Settlement
    },
    {
      path: '/cooperation',
      name: 'Cooperation',
      component: Cooperation
    },
    {
      path: '/buyersMap',
      name: 'BuyersMap',
      component: BuyersMap
    },
    {
      path: '/coopUser',
      name: 'CoopUser',
      component: CoopUser
    },
    {
      path: '/accept_invitation/:token',
      name: 'AcceptInvitation',
      component: AcceptInvitation
    },{
      path: '/sendReVerifyEmail',
      name: 'SendReVerifyEmail',
      component: SendReVerifyEmail
    },
  ]
})
