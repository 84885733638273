<template>
  <v-container >
    <v-card class="d-flex flex-column my-6 mx-auto">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-text class="d-flex justify-center flex-column">
          <v-card-title class="d-flex justify-center pa-0 mt-6 mb-3"
            >ユーザー情報確認</v-card-title
          >
          <div class="mx-9">
            <v-text-field
              v-model="userName"
              readonly
              :counter="50"
              :rules="userNameRules"
              label="ユーザー名"
              required
            ></v-text-field>

            <v-text-field
              v-model="chargeName"
              :counter="50"
              :rules="chargeNameRules"
              label="担当者氏名"
              required
            ></v-text-field>

            <v-text-field
              v-model="zipcode"
              :counter="7"
              :rules="zipcodeRules"
              label="郵便番号"
              required
            ></v-text-field>

            <v-text-field
              v-model="address"
              :counter="256"
              :rules="addressRules"
              label="住所"
              required
            ></v-text-field>

            <v-text-field
              v-model="contact"
              :counter="256"
              :rules="contactRules"
              label="代表者の連絡先"
              required
            ></v-text-field>

            <v-text-field
              v-model="position"
              :counter="50"
              :rules="positionRules"
              label="役職"
              required
            ></v-text-field>

            <v-text-field
              v-model="department"
              :counter="256"
              :rules="departmentRules"
              label="所属部署"
              required
            ></v-text-field>

            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="担当者メールアドレス"
              required
              readonly
            ></v-text-field>

            <div class=coop_wrapper v-if="isBasic">
              <v-switch
                v-model="isMastersCoop"
                :label="`masters からの連携申請を許可する`"
              ></v-switch>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="grey" dark v-bind="attrs" v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span class>
                  ON にすると連携申請の対象となり、あなたのユーザー名と住所が masters の連携先として表示されます。
                  OFF にすると表示されません。
                  </span
                >
              </v-tooltip>
            </div>
            <div class=coop_wrapper>
              <v-switch
                v-model="isBuyersCoop"
                :label="`実需者へ情報を公開する`"
              ></v-switch>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="grey" dark v-bind="attrs" v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span class>
                  ON にすると連携申請の対象となり、あなたの情報が実需者へ公開されます。
                  OFF にすると公開されません。
                  </span
                >
              </v-tooltip>
            </div>
            <v-btn @click="setFarmersInfo">公開情報の設定画面へ</v-btn>
            <div class="text-center">
              <v-btn
                class="primary"
                :disabled="!valid"
                v-on:click="submit">更新</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import AuthUtils from '@/utils/AuthUtils.js'
import router from "../../router";
import axios from 'axios';
import Swal from 'sweetalert2';

// ユーザー登録
export default {
  name: "UserInfo",
  components: {
  },
  mounted() {
    this.reload();
  },
  data() {
    return {
      userName: '',
      userNameRules: [
        v => !!v || 'ユーザー名 が 必要です',
        v => (v && v.length > 3) || "ユーザー名は4文字以上でなければなりません",
        v => (v && v.length <= 50) || 'ユーザー名 は 50文字以内で入力してください',
      ],
      chargeName: '',
      chargeNameRules: [
        v => !!v || '担当者名 が 必要です',
        v => (v && v.length <= 50) || '担当者名 は 50文字以内で入力してください',
      ],
      position: '',
      positionRules: [
        v => !!v || '役職 が 必要です',
        v => (v && v.length <= 50) || '役職 は 50文字以内で入力してください',
      ],
      email: '',
      emailRules: [
        v => !!v || '担当者メールアドレス が 必要です',
        v => /.+@.+\..+/.test(v) || '担当者メールアドレス の形式が正しくありません',
      ],
      zipcode: "",
      zipcodeRules: [
        v => !!v || '郵便番号 が 必要です',
        v => (v && v.length <= 7) || '郵便番号 は 7文字以内で入力してください',
        v => /[0-9]/.test(v) || '郵便番号 は 数字のみで入力してください'
      ],
      address: "",
      addressRules: [
        v => !!v || '住所 が 必要です',
        v => (v && v.length <= 255) || '住所 は 255文字以内で入力してください',
      ],
      contact: "",
      contactRules: [
        v => !!v || '代表者の連絡先 が 必要です',
        v => (v && v.length <= 255) || '代表者の連絡先 は 255文字以内で入力してください',
      ],
      department:"",
      departmentRules: [
        v => !!v || '所属部署 が 必要です',
        v => (v && v.length <= 255) || '所属部署 は 255文字以内で入力してください',
      ],
      isMastersCoop: false,
      isBuyersCoop: false,
      isFarmersInfo: false,
      valid: false
    }
  },
  computed: {
    isUserTypeOther(){
      return this.userType=='その他'
    },
    isBasic() {
      return AuthUtils.isBasic(this.$session);
    },
  },
  methods: {
    validate () {
      return this.$refs.form.validate()
    },
    reload() {
      this.checkLoggedIn();
      AuthUtils.checkAccountPermission(this.$session, router);
      axios.get('/api/current_user/').then(res => {
        const currentUser = res.data;
        this.userName = currentUser.username;
        this.chargeName = currentUser.charge_name;
        this.position = currentUser.position;
        this.email = currentUser.email;
        this.zipcode = currentUser.zipcode;
        this.address = currentUser.address;
        this.contact = currentUser.contact;
        this.department = currentUser.department;
        this.isMastersCoop = currentUser.is_masters_open;
        this.isBuyersCoop = currentUser.is_buyers_open;
        this.isFarmersInfo = currentUser.is_buyers_open;
      })
    },
    resetValidation () {
      return this.$refs.form.resetValidation()
    },
    formReset () {
      return this.$refs.form.reset()
    },
    checkLoggedIn() {
      this.$session.start();
      if (!this.$session.has("token")) {
        this.$session.destroy();
        router.push("/auth");
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `JWT ${this.$session.get("token")}`;
        axios.get('/api/current_user/').then(res => {
          this.$session.set('current_user',res.data);
        }).catch(()=>{
          this.$session.destroy();
          router.push("/auth");
        });
      }
    },
    submit() {
      if (this.validate()) {
        this.loading = true;
        const currentUser = this.$session.get('current_user');
        axios.put(`/api/users/${currentUser.id}/`, {
          'username': this.userName,
          'charge_name': this.chargeName,
          'position': this.position,
          'department': this.department,
          'zipcode': this.zipcode,
          'address': this.address,
          'contact': this.contact,
          'email': this.email,
          'is_active': true,
          'is_masters_open': this.isMastersCoop.toString(),
          'is_buyers_open': this.isBuyersCoop.toString(),
        }).then(() => {
          this.loading = false;
          this.formReset();
          Swal.fire({
            icon: 'info',
            title: 'ユーザーの更新',
            text: 'ユーザーの更新が完了しました',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
          }).then(() => {
            this.reload()
          })
        }).catch((error) => {
          this.loading = false;
          if (error.response.data.email) {
            Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'Emailが重複しています。',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
            })
          } else {
            Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'サーバーエラーです。',
            showConfirmButton:true,
            showCloseButton:false,
            timer:3000
            })
          }
        })
      }
    },
    setFarmersInfo(){
      router.push("/farmersInfo");
    },
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
.coop_wrapper {
  display: flex;
}
</style>
