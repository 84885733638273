<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{on, attrs}">
      <v-btn
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        @click="reload"
      >
        <v-badge
            v-model="isMessage"
            value=" "
            content=" "
            color="red"
            right
          >
          メッセージを開く
        </v-badge>
      </v-btn>
    </template>
    <v-card class="pa-10">
      <v-row class="text-md-center">
        <v-col>
          <h3>{{roomTitle}}</h3>
        </v-col>
        <v-btn justify="end" @click.stop="reload">
          <v-icon >mdi-refresh</v-icon>
        </v-btn>
        <v-btn justify="end" @click.stop="dialog = false">
          <v-icon justify="end" >mdi-close</v-icon>
        </v-btn>
      </v-row>

      <div class="context overflow-y-auto mt-2" ref="content">
        <Balloon v-for="(message, key) in messageList"
          :key="key"
          :message="message.text"
          :name="message.send_user.username"
          :date="message.created_at"
          :isMine="isMine(message)"
        />
      </div>

      <v-card-actions>
        <v-sheet class="d-flex pa-2 pl-5 pr-5 message-box" style="width:100%;">
          <v-text-field v-model="message">
          </v-text-field>
          <v-btn @click.stop="clickRequestButton" :disabled="!message" primary color="primary" class="mt-5 ml-2" style="vertical-align: middle;">
            送信
          </v-btn>
        </v-sheet>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2';

import router from "../../router";
import Balloon from '@/components/comps/Balloon';

export default {
  name: 'MessageListDialog',
  components: {
    Balloon,
  },
  props: {
    cooperation: Object()
  },
  mounted() {
    this.reload()
  },
  data() {
    return {
      dialog: false,
      messageList: [],
      message: '' 
    }
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        this.reload();
        this.readed();
      }
    }
  },
  methods: {
    checkLoggedIn() {
      this.$session.start();
      if (!this.$session.has("token")) {
        this.$session.destroy();
        router.push("/auth");
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `JWT ${this.$session.get("token")}`;
        axios.get('/api/current_user/').then(res => {
          this.$session.set('current_user',res.data);
        }).catch(()=>{
          this.$session.destroy();
          router.push("/auth");
        });
      }
    },
    reload() {
      this.checkLoggedIn();
      axios.get(`/api/message/?message_room_id=${this.cooperation.message_room.id}`).then(res => {
        this.messageList = res.data;
        // DOMのサイクルを回すために必要
        setTimeout(() => {
          if (this.$refs['content']) {
            this.$refs['content'].scrollTop = this.$refs['content'].scrollHeight;
          }
        }, 1);
      });
    },
    readed() {
      // 未読を既読にする
      // FIXME Websocket 双方向通信にした方が良い
      const current_user = this.$session.get("current_user");
      const notReads = this.messageList.filter((message) => message.send_user.id != current_user.id && !message.readed_at);
      const readDate = new Date();
      notReads.forEach(message => {
        axios.patch(`/api/message/${message.id}/`, {
          'readed_at': readDate.toISOString()
        }).then(()=>{
          this.$emit('readed');
        });
      });
    },
    isMine(message) {
      const current_user = this.$session.get("current_user");
      return message.send_user.id == current_user.id;
    },
    clickRequestButton() {
      const current_user = this.$session.get("current_user");
      axios.post('/api/message/', {
        'message_room_id': this.cooperation.message_room.id,
        'text': this.message,
        'send_user_id': current_user.id
      }).then(() => {
        this.reload();
        this.readed();
      }).catch((err) => {
        const errorMessage = err.response?.data?.detail ? err.response.data.detail : '送信エラーが発生しました。'
        Swal.fire({
          icon: 'warning',
          title: '送信エラー',
          text: errorMessage,
          showCloseButton: true
        }).then(() => {
          this.reload()
          this.readed();
        })
      })
    }
  },
  computed : {
    roomTitle(){
      return this.cooperation.message_room ? this.cooperation.message_room.title : '';
    },
    isMessage() {
      const currentUser = this.$session.get('current_user');
      return this.messageList.filter((message) => message.send_user.id != currentUser.id && !message.readed_at).length > 0;
    }
  }
}
</script>
<style scoped>
.is_cooperated {
  color: #808080;
}
.fullWidth {
  width: 100%;
}
.message-box {
  border: solid 1px #CECECE;
}
.context {
  max-height: 420px;
}
</style>
