<template>
  <v-container fluid>
    <v-layout
      row
      fill-height
      justify-center
      align-center
      v-if="loading"
    >
      <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
      />
    </v-layout>
    <v-row dense class="mx-auto">
      <v-col
          :cols="4"
          >
        <v-card class="d-flex flex-column overflow-y-auto overflow-x-hidden" v-bind:style="{height:yearGroupHeight}">
          <v-card-text class="d-flex justify-center flex-column">
            <v-card-title class="d-flex justify-center pa-0 mt-0 mb-3">
              栽培グループの登録・編集
            </v-card-title>
            <v-card-text>
              定植時期や期間などのグループ設定、確認、編集を行えます。
            </v-card-text>
            <v-card-text>
              <PlantMasterDialog  :cropItems="cropItems" @reload="reload">
              </PlantMasterDialog>
              &nbsp;
              <CropDialog  @reload="reload">
              </CropDialog>
            </v-card-text>
            <v-tabs
              v-model="tab"
              align-with-title
              show-arrows
            >
              <v-tabs-slider color="blue"></v-tabs-slider>

                <v-tab
                  v-for="cropItem in cropItems"
                  :key="cropKey(cropItem)"
                  @click.stop = "onClickTab(cropItem)"
                  @click.right.prevent="onClickDeleteTab(cropItem)"
                >
                  <span>
                    {{ cropItem.text }}
                  </span>
                </v-tab>
            </v-tabs>
            <v-list>
              <template v-for="(plant_master, index) in displayPlantMasterList">
                <v-list-group
                  :value="false"
                  :key="plant_master.id"
                  no-action
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      作物 : {{ plant_master.crop.name }} <br/> 名前 : {{ plant_master.name }}
                    </v-list-item-content>
                    <v-list-item-action>
                      <PlantMasterDialog  :cropItems="cropItems" :plantMaster="plant_master" @reload="reload">
                      </PlantMasterDialog>
                    </v-list-item-action>
                    <v-list-item-action>
                      <v-icon @click.stop="onClickDeletePlantMaster(plant_master)">mdi-delete</v-icon>
                    </v-list-item-action>
                  </template>

                  <template v-for="(group_year) in plant_master.group_year_list">
                    <v-list-group
                      :value="false"
                      no-action
                      sub-group
                      :key="group_year.id"
                    >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>
                            年度 : {{ new Date(group_year.date).toISOString().substr(0, 4) }}
                            &nbsp; 登録数 : {{ registerPlantNumByGroupYear(group_year)}}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <AreaGroupDialog  :group_year="group_year" @reload="reload" :plantMaster="group_year.plant_master">
                          </AreaGroupDialog>
                        </v-list-item-action>
                        <v-list-item-action>
                          <v-icon @click.stop="onClickDeleteAreaGroup(group_year)">mdi-delete</v-icon>
                        </v-list-item-action>
                      </template>

                      <template v-for="(plant_info) in group_year.plant_info_list">
                        <v-list-item :key="plant_info.id" link>
                          <v-list-item-title class="plant-info">
                            <span v-bind:class="{stop: plant_info.is_stop}">
                              圃場 : {{ plant_info.field.name }}
                            </span>
                            <v-spacer></v-spacer>
                            <span v-bind:class="{stop: plant_info.is_stop}">
                              定植日 : {{ new Date(plant_info.plant_date).toISOString().substr(0, 10) }}
                            </span>
                            <v-spacer></v-spacer>
                            <span v-bind:class="{stop: plant_info.is_stop}">
                              収穫日（収穫予定日） : {{ new Date(plant_info.harvest_date).toISOString().substr(0, 10) }}
                            </span>
                            <v-spacer v-if="plant_info.is_stop"></v-spacer>
                            <span class="red--text" v-if="plant_info.is_stop">*停止済み</span>
                          </v-list-item-title>
                          <v-list-item-action v-if="!plant_info.is_stop">
                            <PlantInfoDialog  :fieldItems="fieldItems" :plantMaster="plant_master" :groupYear="group_year" :plantInfo="plant_info" @reload="reload">
                            </PlantInfoDialog>
                          </v-list-item-action>
                          <v-list-item-action>
                            <v-icon @click.stop="onClickDeletePlantInfo(plant_info)">mdi-delete</v-icon>
                          </v-list-item-action>

                        </v-list-item>
                        <v-divider
                          v-if="index < plant_info.length - 1"
                          :key="`plant_info_div_${plant_info.id}`"
                        ></v-divider>
                      </template>
                      <v-list-item link>
                        <v-list-item-title>
                          <PlantInfoDialog  :fieldItems="fieldItems" :plantMaster="plant_master" :groupYear="group_year" @reload="reload">
                          </PlantInfoDialog>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-group>
                  </template>

                  <v-list-item>
                    <v-list-item-title>
                      <AreaGroupDialog @reload="reload" :plantMaster="plant_master.id">
                      </AreaGroupDialog>
                    </v-list-item-title>
                  </v-list-item>

                </v-list-group>
              </template>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col 
        :cols="8"
      >
        <div class="viewer" v-bind:style="{height:viewerHeight}">
          <vc-viewer @ready="ready" :infoBox="false" :selectionIndicator="false">
            <vc-layer-imagery>
              <vc-provider-imagery-arcgis-mapserver :url="url" />
            </vc-layer-imagery>

            <vc-entity v-for="polygon in polygons" :key="polygon.id" @click="onClickPolygon(polygon)">
              <vc-graphics-polygon
                :hierarchy="convertHierarchy(polygon)"
                :material="material"
                :extrudedHeight="0.0"
                :closeTop="false"
                :closeBottom="false"
                :ref="polygonRef(polygon.id)"
                :outline="true"
                :outlineColor="outLineMaterial"
                :outlineWidth="5"
              ></vc-graphics-polygon>
            </vc-entity>
          </vc-viewer>
        </div>
      </v-col>
    </v-row>
    <FieldPlantInfoDialog ref="fieldPlantInfoDialog" @reload="reload" :field="selectFieldItem" :plantMasters="plantMasters">
    </FieldPlantInfoDialog>
  </v-container>
</template>

<script>
import PlantMasterDialog from '@/components/comps/PlantMasterDialog'
import AreaGroupDialog from '@/components/comps/AreaGroupDialog'
import PlantInfoDialog from '@/components/comps/PlantInfoDialog'
import FieldPlantInfoDialog from '@/components/comps/FieldPlantInfoDialog'
import CropDialog from '@/components/comps/CropDialog'
import AuthUtils from '@/utils/AuthUtils.js'
import router from "../../router";
import axios from 'axios';
import Swal from 'sweetalert2';

// 栽培情報
export default {
  name: "PlantMaster",
  components: {
    PlantMasterDialog,
    AreaGroupDialog,
    PlantInfoDialog,
    CropDialog,
    FieldPlantInfoDialog
  },
  mounted() {
    this.reload();
    this.windowSize.width = window.innerWidth;
    this.windowSize.height = window.innerHeight;
  },
  data() {
    return {
      Cesium: {},
      viewer: {},
      loading: false,
      geoready: false,
      url: "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer",
      tab: "",
      crop: "",
      polygons: [],
      material: [0.1, 1, 0.2, 0.5],
      outLineMaterial: [0.1, 1, 0.2, 1],
      selectFieldItem: {},
      cropItems: [
        {'text':'作物を選択してください', 'value':''}
      ],
      fieldItems: [
        {'text':'圃場を選択してください', 'value':''}
      ],
      plantMasterItems: [
        {'text':'栽培グループを選択してください', 'value':''}
      ],
      groupYearItems: [
        {'text':'年度を選択してください', 'value':''}
      ],
      windowSize: {
        width:0,
        height:0
      },
      plantMasters: []
    };
  },
  methods: {
    ready(cesiumInstance) {
      const { Cesium, viewer } = cesiumInstance;
      this.Cesium = Cesium;
      this.viewer = viewer;
      viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
      this.zoom();
    },
    reload() {
      this.checkLoggedIn();
      // 閲覧アカウントは侵入不可
      AuthUtils.checkAccountPermission(this.$session, router);

      const current_user = AuthUtils.currentUser(this.$session);
      axios.get(`/api/geoapi_from_postal/?zipcode=${current_user.zipcode}`).then((res) => {
        this.zoomX = res.data.x;
        this.zoomY = res.data.y;
        this.geoready = true;
      });

      axios.get(`/api/polygon_detail/?user_id=${current_user.id}`).then((res) => {
        this.polygons = res.data;
        this.polygons.forEach((polygon) => {
          polygon.poslist.sort((a, b) => a.sequence - b.sequence);
        })
      });

      axios.get(`/api/fields/?user_id=${current_user.id}`).then((res) => {
        this.fieldItems = res.data.map((data)=>{
          return {'text':`${data.name} ${data.address}`, 'value':data.id}
        });
      });

      axios.get(`/api/plant_master/?user_id=${current_user.id}`).then((res) => {
        this.plantMasters = res.data;
      });

      axios.get('/api/crops/').then((res) => {
        this.cropItems = res.data.map((d)=> {
          return {'text':d.name, 'value':d.id}
        });
        if (this.crop == "" && this.cropItems.length != 0) {
          this.onClickTab(this.cropItems[0])
          this.tab = this.cropKey(this.cropItems[0]);
        }
      });
    },
    zoom(){
      if (this.geoready){
        this.viewer.camera.flyTo({destination:this.Cesium.Cartesian3.fromDegrees(this.zoomX, this.zoomY, 12000.0)})
      } else {
        setTimeout(this.zoom, 100);
      }
    },
    polygonRef(polygon_id) {
      return `polygon_${polygon_id}`;
    },
    convertHierarchy(polygon) {
      return polygon.poslist.map((pos) => {
        return { "lng": pos.lng, "lat": pos.lat, height: 0 }
      });
    },
    onClickTab(cropItem) {
      this.crop = cropItem.value;
    },
    onClickDeleteTab(cropItem){
      Swal.fire({
        icon: 'warning',
        title: '作物シートの削除',
        text: 'この作物シートを削除しますか？削除する場合この栽培年度に紐づく栽培情報も削除されます。',
        showConfirmButton:true,
        showCloseButton:true
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          axios.delete(`/api/crops/${cropItem.value}/`).then(() => {
            this.loading = false;
            this.reload();
            Swal.fire({
              icon: 'success',
              title: '削除完了',
              text: '削除しました',
              showConfirmButton:false,
              showCloseButton:true
            })
          });
        }
      })
    },
    cropKey(cropItem){
      return `crop_${cropItem.value}`
    },
    onClickDeleteAreaGroup(group_year) {
      Swal.fire({
        icon: 'warning',
        title: '栽培年度の削除',
        text: 'この栽培年度を削除しますか？ 削除する場合この栽培年度に紐づく栽培情報も削除されます。',
        showConfirmButton:true,
        showCloseButton:true
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          axios.delete(`/api/group_years/${group_year.id}/`).then(() => {
            this.loading = false;
            this.reload();
            Swal.fire({
              icon: 'success',
              title: '削除完了',
              text: '削除しました',
              showConfirmButton:false,
              showCloseButton:true
            })
          });
        }
      })
    },
    onClickDeletePlantMaster(plantMaster) {
      Swal.fire({
        icon: 'warning',
        title: '栽培情報マスタの削除',
        text: 'この栽培情報マスタを削除しますか？',
        showConfirmButton:true,
        showCloseButton:true
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          axios.delete(`/api/plant_master/${plantMaster.id}/`).then(() => {
            this.loading = false;
            this.reload();
            Swal.fire({
              icon: 'success',
              title: '削除完了',
              text: '削除しました',
              showConfirmButton:false,
              showCloseButton:true
            })
          });
        }
      })
    },
    onClickDeletePlantInfo(plantInfo) {
      Swal.fire({
          icon: 'warning',
          title: '栽培情報の削除',
          text: 'この栽培情報を削除しますか？',
          showConfirmButton:true,
          showCloseButton:true
        }).then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            axios.delete(`/api/plant_info/${plantInfo.id}/`).then(() => {
              this.loading = false;
              this.reload();
              Swal.fire({
                icon: 'success',
                title: '削除完了',
                text: '削除しました',
                showConfirmButton:false,
                showCloseButton:true
              })
            });
          }
        })
    },
    registerPlantNumByGroupYear(groupYear) {
      return groupYear.plant_info_list.length;
    },
    onClickPolygon(polygon) {
      const fieldItem = this.fieldItems.filter((fieldItem) => fieldItem.value == polygon.field.id)[0];
      this.selectFieldItem = fieldItem;
      this.$refs['fieldPlantInfoDialog'].showModal();
    },
    /// TODO to mixin
    checkLoggedIn() {
      this.$session.start();
      if (!this.$session.has("token")) {
        this.$session.destroy();
        router.push("/auth");
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `JWT ${this.$session.get("token")}`;
        axios.get('/api/current_user/').then(res => {
          this.$session.set('current_user',res.data);
        }).catch(()=>{
        this.$session.destroy();
          router.push("/auth");
        });
      }
      
    }
  },
  computed : {
    viewerHeight() {
      return `${this.windowSize.height - 100}px`
    },
    yearGroupHeight() {
      // TODO height
      return `${this.windowSize.height - 52 - 100}px`
    },
    displayPlantMasterList() {
      if (!this.crop) {
        return this.plantMasters;
      }
      return this.plantMasters.filter(plant_master => plant_master.crop.id == this.crop);
    }
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
.v-list-item__title, .v-list-item__subtitle {
  white-space: break-spaces;
}
.v-list-item__action {
  margin-left: 16px;
}
.v-card {
  display: block;
}
.stop {
  text-decoration: line-through;
}
</style>
