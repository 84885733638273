<template>
  <div class="d-flex pa-2" :class="{mine: isMine}">
    <div class="black--text headline">
      <v-avatar v-if="!isMine">
        <v-img v-if="imagePath" :src="imagePath" contain alt="圃場画像"></v-img>
        <v-img v-if="!imagePath" src="/static/no_image.png" contain alt="圃場画像"></v-img>
      </v-avatar>
    </div>
    <div class="message-area">
      <div class="message-balloon-area">
        <div>
          <div
              class="ml-5 mr-5 pt-2 pb-2 pl-5 pr-5 message-balloon"
              :class="{other: !isMine}"
              location="start"
            >{{ message }}</div>
        </div>
        <span class="description ml-5 mr-5" >{{name}} &nbsp; {{createdDate}}</span>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Balloon',
  props: {
    isMine: Boolean(),
    name: String(),
    message: String(),
    date: String(),
    imagePath: String()
  },
  mounted(){
  },
  data() {
    return {
      dialog: false
    }
  },
  methods: {
  },
  computed: {
    createdDate() {
      const date = new Date(this.date);
      const year = date.getFullYear().toString().padStart(4, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hour = date.getHours().toString().padStart(2, '0');
      const minute = date.getMinutes().toString().padStart(2, '0');

      return`${year}/${month}/${day} ${hour}:${minute}`;
    }
  }
}
</script>
<style scoped>
div.mine {
  display: flex;
  justify-content: flex-end;
}
.message-area {
  display: flex;
}
div.message-balloon-area {
  display: block;
  text-align: left;
}
.mine div.message-balloon-area {
  text-align: right;
}
.message-balloon {
  display: inline-block;
  background-color: #EEEEEE;
  text-align: left;
  color: #000000;
  border-radius: 20px;
  justify-content: flex-start;
  position:relative;
}
.message-balloon:before {
    content: '';
    position: absolute;
    top: 10px;
    border: 8px solid transparent;
}
.message-balloon.other:before {
    left: -20px;
    transform: rotate(-180deg);
    border-left: 18px solid #EEEEEE;
}
.mine .message-balloon{
  display: inline-block;
  background-color: #85FF49;
}
.mine .v-avatar{
  justify-content: flex-end;
}
.description {
  font-size: 8px;
  justify-content: flex-end;
}

</style>
