<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        dark
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-note-check</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-title>
          <span class="text-h5">アラート確認履歴</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-data-table
              :headers="headers"
              :items="alertConfirmList"
              :items-per-page="5"
              class="inquiry"
            >
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            @click="onClickDelete"
          >
            閉じる
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

// 栽培情報情報登録ダイアログ
export default {
  name: "AlertConfirmListDialog",
  components: {
  },
  props: {
    plantInfo: Object()
  },
  mounted() {
  },
  data() {
    return {
      description: '',
      headers: [
        { text: "メモ", value: "description" },
        { text: "確認日時", value: "date" },
      ],
      alertConfirmList: [],
      valid: false,
      dialog: false
    }
  },
  watch: {
    dialog(){
      axios.get(`/api/alert_memo/?plant_info=${this.plantInfo.id}`).then((res) => {
        this.alertConfirmList = res.data;
      });
    }
  },
  methods: {
    onClickDelete() {
      this.dialog = false
    }
  }
};
</script>

<style scoped>
.searchArea {
  height:100%;
}
.theme--dark.v-list,
.v-list-item > .theme--dark.v-card {
  background-color: #3c3c3c;
}
</style>
